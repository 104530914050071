import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from "rxjs";
import { debounceTime, switchMap, takeUntil, tap } from "rxjs/operators";
import { DateDropdownComponent } from "src/app/shared/components/date-dropdown/date-dropdown.component";
import { EmptyStateComponent } from "src/app/shared/components/empty-state/empty-state.component";
import { PageHeaderComponent } from "src/app/shared/components/page-header/page-header.component";
import {
  IPaginationMetadata,
  TApiStatus,
} from "src/app/shared/interfaces/common.interface";
import {
  IRewardTypes,
  IRewardsRequestForm,
} from "src/app/shared/interfaces/rewards.interface";
import { RewardsService } from "./_services/rewards.service";
import { EventRequestForm } from "./ui/components/event-request-form/event-request-form.component";
import { RewardsTableFiltersComponent } from "./ui/components/rewards-table-filters/rewards-table-filters.component";
import { RewardsTableComponent } from "./ui/components/rewards-table/rewards-table.component";
import { SubmittedDialog } from "./ui/components/submitted-dialog/submitted-dialog.component";

@Component({
  selector: "app-rewards",
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    MatPaginatorModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatTableModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    RewardsTableComponent,
    RewardsTableFiltersComponent,
    MatDividerModule,
    MatBottomSheetModule,
    EventRequestForm,
    TranslateModule,
    MatNativeDateModule,
    MatDialogModule,
    DateDropdownComponent,
    NgxSkeletonLoaderModule,
    EmptyStateComponent,
    SubmittedDialog,
  ],
  templateUrl: "./rewards.component.html",
  styleUrls: ["./rewards.component.scss"],
})
export class RewardsComponent {
  public isLoading: boolean = false;
  public isError: boolean = false;

  searchText: string;
  public filterProperties: any = {
    searchValue: "",
    startDate: null,
    endDate: null,
    date: null,
  };
  apiStatus$ = new BehaviorSubject<TApiStatus>("LOADING");

  searchChange: Subject<void> = new Subject<void>();
  public productList: any[] = [];
  paginationProperties: IPaginationMetadata = {
    offset: 1,
    limit: 20,
    length: 0,
    sortIndex: 0,
    sortDirection: "desc",
  };
  currentTabIndex: number = 0;
  rewardPackages: any[];

  eventColumnConfig: string[] = [
    "submissionDate",
    "name",
    "email",
    "headcount",
    "totalHours",
    "dateOfEvent",
  ];
  bloodTestColumnConfig: string[] = [
    "submissionDate",
    "name",
    "email",
    "headcount",
    "dateOfEvent",
  ];

  employeeRewardTypeId: string;

  rewardTypes: IRewardTypes[] = [];
  employeeRewardTypeName: string;
  private unsubscribeManually$ = new Subject<void>();
  private list = new BehaviorSubject<any[]>([]);
  submissionStatusSubscription: Subscription;
  public listingData$: Observable<any[]> = this.list.asObservable();
  public filterQuery$ = new BehaviorSubject<any>(this.filterProperties);
  public pagination$ = new BehaviorSubject<IPaginationMetadata>(
    this.paginationProperties,
  );

  constructor(
    private rewardsService: RewardsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.employeeRewardTypeName = params["type"];
      this.getRewardsId();
      this.getRewardsListing();
    });
    this.submissionStatusSubscription = this.rewardsService
      .getSubmissionStatus()
      .subscribe((status) => {
        if (status) {
          // Call the getRewardsListing function or any other action needed after submission
          this.getRewardsListing();
          // Reset the submission status after handling
          this.rewardsService.updateSubmissionStatus(false);
        }
      });
    this.initData();
  }
  private async initData(): Promise<void> {
    this.getRewardTypes().then(() => {
      if (this.employeeRewardTypeId) {
        this.getRewardsListing();
      }
    });
  }

  public searchChanged(): void {
    this.searchChange.next();
  }

  getRewardsId(): void {
    switch (this.currentTabIndex) {
      case 0:
        this.employeeRewardTypeId = this.findRewardTypeIdByName("Event");
        this.employeeRewardTypeName = "event";

        break;
      case 1:
        this.employeeRewardTypeId = this.findRewardTypeIdByName(
          "Blood Test Quotation",
        );
        this.employeeRewardTypeName = "bloodTest";

        break;
      case 2:
        this.employeeRewardTypeId = this.findRewardTypeIdByName(
          "Vaccination Quotation",
        );
        this.employeeRewardTypeName = "vaccination";

        break;
      case 3:
        this.employeeRewardTypeId = "";
        this.employeeRewardTypeName = "promotion";

        break;

      default:
        break;
    }
  }
  public tabChanged(event: any): void {
    this.currentTabIndex = event.index;
    this.getRewardsId();

    this.handleClearSearch();
    this.router.navigate(["/rewards"], {
      queryParams: { type: this.employeeRewardTypeName },
    });
  }
  ngOnDestroy(): void {
    this.unsubscribeManually$.next();
    this.unsubscribeManually$.complete();
    this.submissionStatusSubscription.unsubscribe();
  }
  get isLoadingState() {
    return this.apiStatus$.value === "LOADING";
  }
  get showEmptyState() {
    return this.apiStatus$.value === "SUCCESS" && this.list.value.length === 0;
  }
  public resetAllFilters(): void {
    this.filterQuery$.next(this.filterProperties);
  }

  handleSearchChange(searchQuery: string) {
    const { startDate, endDate, statusList, date } = this.filterProperties;
    this.filterProperties.searchValue = searchQuery;
    this.filterQuery$.next({
      searchValue: searchQuery,
      startDate,
      endDate,
    });

    this.applyFilterToRoute();
  }

  handleFilterChange(event: any) {
    const {
      list,
      filterType,
      startDateFilter,
      endDateFilter,
      selectedDateFilter,
    } = event;

    const { searchValue, startDate, statusList, endDate, date } =
      this.filterProperties;

    if (filterType === "date") {
      let dateType = selectedDateFilter;
      this.filterProperties.startDate = startDateFilter;
      this.filterProperties.endDate = endDateFilter;

      if (startDateFilter === null || endDateFilter === null) {
        dateType = null;
      }

      this.filterProperties.date = dateType;

      this.filterQuery$.next({
        searchValue,
        startDate: startDateFilter,
        endDate: endDateFilter,
        date: dateType,
      });
    }

    this.applyFilterToRoute();
  }

  handleClearSearch() {
    const { statusList, productList, startDate, endDate } =
      this.filterProperties;
    this.isLoading = true;
    this.filterProperties.searchValue = "";
    if (this.employeeRewardTypeId) {
      this.filterQuery$.next({
        searchValue: "",
        startDate,
        endDate,
      });
    }

    this.router.navigate([]);
  }
  handleClearAllFilters() {
    this.filterProperties = {
      searchValue: "",
      startDate: null,
      endDate: null,
      date: null,
    };
    this.resetAllFilterQuery();
  }

  private resetAllFilterQuery() {
    this.filterQuery$.next(this.filterProperties); // Emit filter properties reset first

    this.router.navigate([], {
      queryParams: {
        search: null,
        startDate: null,
        endDate: null,
      },
      queryParamsHandling: "merge",
    });
  }
  handlePageEvent(e: PageEvent) {
    this.paginationProperties = {
      offset: e.pageIndex + 1,
      limit: e.pageSize,
      length: this.paginationProperties.length,
      sortIndex: this.paginationProperties.sortIndex,
      sortDirection: this.paginationProperties.sortDirection,
    };

    this.pagination$.next({ ...this.paginationProperties });

    this.applyFilterToRoute();
  }

  private applyFilterToRoute(): void {
    const { searchValue, startDate, endDate } = this.filterProperties;
    const { limit, offset } = this.paginationProperties;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        search: searchValue ? searchValue : null,
        startDate,
        endDate,
        limit,
        offset,
      },
      replaceUrl: true,
    });
  }

  // API FUNCTIONS
  private getRewardTypes(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.rewardsService
        .getRewardTypes()
        .pipe(takeUntil(this.unsubscribeManually$))
        .subscribe({
          next: (x) => {
            this.rewardTypes = x;
            this.employeeRewardTypeId = this.findRewardTypeIdByName("Event");
            this.employeeRewardTypeName = "event";
            this.router.navigate(["/rewards"], {
              queryParams: { type: "event" },
            });

            resolve(x);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  private getRewardsListing(): void {
    if (!this.employeeRewardTypeId) {
      // If employeeRewardTypeId is not set, don't make an API call
      return;
    }

    this.unsubscribeManually$.next();
    this.unsubscribeManually$.complete();
    this.unsubscribeManually$ = new Subject<void>();
    combineLatest([this.pagination$, this.filterQuery$.pipe(debounceTime(500))])
      .pipe(
        switchMap(([pagination, filterQuery]) => {
          this.list.next([]);
          this.apiStatus$.next("LOADING");

          let objRequest = {
            text: filterQuery.searchValue,
            limit: pagination.limit,
            offset: pagination.offset,
            columnIndex: pagination.sortIndex,
            sortDirection: pagination.sortDirection,
            startDate: filterQuery.startDate,
            endDate: filterQuery.endDate,
            statusList: filterQuery.statusList,
            productList: filterQuery.productList,
            employeeRewardTypeId: this.employeeRewardTypeId,
          };

          return this.rewardsService
            .getListing(
              objRequest.limit,
              objRequest.offset,
              objRequest.text,
              objRequest.columnIndex,
              objRequest.sortDirection,
              objRequest.employeeRewardTypeId,
              objRequest.startDate,
              objRequest.endDate,
            )
            .pipe(takeUntil(this.unsubscribeManually$));
        }),
        tap({
          next: () => {
            this.apiStatus$.next("SUCCESS");
          },
          error: () => {
            this.apiStatus$.next("ERROR");
          },
        }),
        takeUntil(this.unsubscribeManually$),
      )
      .subscribe({
        next: (response) => {
          this.list.next(response.data);
          this.paginationProperties = {
            ...this.paginationProperties,
            length: response.count,
          };
        },
        error: (err) => {
          // Handle error, e.g., this.alertService.openSnackBar(err.error);
        },
      });
  }

  private getPackagesById(rewardTypeId: string): Promise<any> {
    // this.ngxSpinnerService.show();
    return new Promise<any>((resolve, reject) => {
      this.rewardsService
        .getPackagesById(rewardTypeId)
        .pipe(takeUntil(this.unsubscribeManually$))
        .subscribe({
          next: (x) => {
            resolve(x);
            return x;
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  private getQuotationDetails(quotationId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.rewardsService
        .getQuotationDetailsById(quotationId)
        .pipe(takeUntil(this.unsubscribeManually$))
        .subscribe({
          next: (x) => {
            resolve(x);
            return x;
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  private submitNewQuotation(reqObj: IRewardsRequestForm): void {
    this.rewardsService
      .requestNewQuotation(reqObj, this.employeeRewardTypeId)
      .pipe(takeUntil(this.unsubscribeManually$))
      .subscribe({
        next: () => {
          // this.ngxSpinnerService.hide();
          // this.openRequestSubmittedSuccessDialog();
        },
        error: (err) => {
          // this.ngxSpinnerService.hide();
          // this.alertService.openSnackBar(err.error);
        },
      });
  }

  // END API FUNCTIONS

  // // DIALOG FUNCTIONS

  async requestQuotation(): Promise<void> {
    const rewardPackages = await this.getPackagesById(
      this.employeeRewardTypeId,
    );
    if (rewardPackages) {
      const requestNewEventDialog = this._bottomSheet.open(EventRequestForm, {
        backdropClass: "sidesheet-container",
        data: {
          rewardPackages: rewardPackages,
          rewardTypeName: this.employeeRewardTypeName,
          rewardId: this.employeeRewardTypeId,
        },
        disableClose: true,
      });
      requestNewEventDialog.afterDismissed().subscribe((reqObj) => {
        if (reqObj) {
          this.submitNewQuotation(reqObj);
        }
      });
    }
  }

  // async openViewMoreDialog(event: Event, quotationId: string): Promise<void> {
  // 	event.preventDefault();
  // 	const quotationDetails = await this.getQuotationDetails(quotationId);
  // 	this.bottomSheet.open(RewardViewMoreDialogComponent, {
  // 		backdropClass: "sidenav-container",
  // 		data: {
  // 			quotationDetails: quotationDetails,
  // 			rewardTypeName: this.employeeRewardTypeName,
  // 		},
  // 		disableClose: true,
  // 	});
  // }
  // END DIALOG FUNCTIONS

  private findRewardTypeIdByName(name: string): string {
    const foundRewardType = this.rewardTypes.find(
      (type) => type.name.toLowerCase() === name.toLowerCase(),
    );
    if (foundRewardType) {
      return foundRewardType.id;
    } else return null;
  }

  getRewards() {
    window.open(
      "https://www.policystreet.com/employee-benefits-rewards/",
      "_blank",
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SubmittedDialog, {
      backdropClass: "dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
