import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
  FormsModule,
  FormBuilder,
} from "@angular/forms";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { IUserProfile } from "src/app/shared/interfaces/user.interface";
import { UserService } from "../../../_services/profile.service";
@Component({
  selector: "action-form",
  templateUrl: "./action-form.component.html",
  standalone: true,
  imports: [
    MatListModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatButtonModule,
    FormsModule,
  ],
  styleUrls: ["./action-form.component.scss"],
})
export class ActionForm {
  updateFormGroup: FormGroup;
  passwordChangeFormGroup: FormGroup;
  formData: any = {};
  callback: () => void;
  hideCurrentPassword: boolean = true;
  hideNewPassword: boolean = true;
  hideRepeatNewPassword: boolean = true;
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ActionForm>,
    private userService: UserService,
    private fb: FormBuilder,

    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      actionType: string;
      userData: IUserProfile;
      callback: () => void;
    },
  ) {
    this.formData = this.data.userData;
    this.callback = this.data.callback;
  }

  ngOnInit() {
    this.updateFormGroup = this.fb.group({
      fullName: new FormControl(this.formData.fullName, [Validators.required]),
      email: new FormControl({ value: this.formData.email, disabled: true }, [
        Validators.required,
      ]),
      position: new FormControl(this.formData.position, [Validators.required]),
      phoneNumber: new FormControl(this.formData.phoneNumber, [
        Validators.required,
      ]),
    });
    this.passwordChangeFormGroup = this.fb.group({
      currentPassword: new FormControl(null, [Validators.required]),
      newPassword: new FormControl(null, [Validators.required]),
      repeatNewPassword: new FormControl(null, [Validators.required]),
    });
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  onUpdate() {
    let data = {
      email: this.formData.email,
      fullName: this.updateFormGroup.value.fullName,
      position: this.updateFormGroup.value.position,
      phone: this.updateFormGroup.value.phoneNumber,
    };

    this.userService.updateProfileInfo(data).subscribe({
      next: (x) => {
        this._bottomSheetRef.dismiss(true);
        this.callback();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  onPasswordChange() {
    let data = {
      oldPassword: this.passwordChangeFormGroup.value.currentPassword,
      newPassword: this.passwordChangeFormGroup.value.newPassword,
      confirmNewPassword: this.passwordChangeFormGroup.value.repeatNewPassword,
    };

    this.userService.updateAccountPassword(data).subscribe({
      next: (x) => {
        this._bottomSheetRef.dismiss(true);
        this.callback();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  disabledCondition(): boolean {
    let formGroupValue = this.updateFormGroup.value;
    let userDetails = this.formData;
    if (
      this.updateFormGroup.invalid ||
      (formGroupValue.fullName == userDetails.fullName &&
        formGroupValue.position == userDetails.position &&
        formGroupValue.phoneNumber == userDetails.phoneNumber)
    ) {
      return true;
    } else {
      return false;
    }
  }
  close(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
