import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ApiService } from "src/app/shared/constants/api/api.service";
import { api } from "src/app/shared/constants/api/api-url";

@Injectable({
  providedIn: "root",
})
export class EmployeesService {
  basePath: string = environment.url;

  header = {
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient, private apiService: ApiService) {}

  downloadTemplate(objRequest?: any, options?: any): Observable<any> {
    return this.apiService.getDocument(
      this.basePath + api.EMPLOYEE_MOVEMENT_DOWNLOAD_TEMPLATE,
      this.header,
    );
  }

  downloadCVVTemplate(objRequest?: any, options?: any): Observable<any> {
    return this.apiService.getDocument(
      this.basePath + api.EMPLOYEE_MOVEMENT_DOWNLOAD_CSV,
      this.header,
    );
  }

  getStatusList(objRequest?: any, options?: any): Observable<any> {
    return this.apiService.get(
      this.basePath + api.EMPLOYEE_MOVEMENT_GET_STATUS_LIST,
      this.header,
    );
  }

  getEmployeeMovementLists(objRequest: any, options?: any): Observable<any> {
    return this.apiService.post(
      this.basePath + api.EMPLOYEE_MOVEMENT_GET_LIST,
      objRequest,
      this.header,
    );
  }

  downloadEmployeeMovementDocumentById(
    objRequest?: any,
    options?: any,
  ): Observable<any> {
    return this.apiService.getDocument(
      this.basePath + api.EMPLOYEE_DOCUMENT_DOWNLOAD_BY_ID + objRequest,
      this.header,
    );
  }

  getStatusHistoryById(objRequest: any, options?: any): Observable<any> {
    return this.apiService.get(
      this.basePath + api.EMPLOYEE_MOVEMENT_GET_STATUS_HISTORY + objRequest,
      this.header,
    );
  }

  deleteEmployeeMovementById(objRequest: any, options?: any): Observable<any> {
    return this.apiService.post(
      this.basePath + api.EMPLOYEE_MOVEMENT_DELETE_MOVEMENT_BY_ID + objRequest,
      this.header,
    );
  }

  uploadDocument(objRequest?: any, options?: any): Observable<any> {
    return this.apiService.post(
      this.basePath + api.EMPLOYEE_MOVEMENT_UPLOAD,
      objRequest,
      {
        reportProgress: true,
        observe: "events",
      },
    );
  }
}
