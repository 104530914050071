import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  debounceTime,
  delay,
  map,
  switchMap,
  tap,
} from "rxjs";
import { MultiDropdownComponent } from "src/app/shared/components/multi-dropdown/multi-dropdown.component";
import { PageHeaderComponent } from "src/app/shared/components/page-header/page-header.component";
import { SiteHeaderComponent } from "src/app/shared/components/site-header/site-header.component";
import { StatusTagComponent } from "src/app/shared/components/status-tag/status-tag.component";
import {
  IFilterSelection,
  IPaginationMetadata,
  TApiStatus,
} from "src/app/shared/interfaces/common.interface";
import { getStatusColorMapping } from "src/app/shared/utils/utils.function";
import { UploadPaymentReceiptComponent } from "./_components/upload-payment-receipt/upload-payment-receipt.component";
import {
  IBilling,
  IBillingDoc,
  IBillingResponse,
  IBillingsFilter,
} from "./_interfaces/billings.interface";
import { BillingsService } from "./_services/billings.service";
import { EmptyStateComponent } from "src/app/shared/components/empty-state/empty-state.component";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { ViewPaymentHistoryDialogComponent } from "./_components/view-payment-history-dialog/view-payment-history-dialog.component";
import { FileFunction } from "src/app/shared/utils/file.function";

@Component({
  selector: "app-billings",
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    StatusTagComponent,
    MatTableModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    SiteHeaderComponent,
    TranslateModule,
    FormsModule,
    MatMenuModule,
    NgxSkeletonLoaderModule,
    MultiDropdownComponent,
    MatBottomSheetModule,
    MatTooltipModule,
    EmptyStateComponent,
    MatDialogModule,
  ],

  templateUrl: "./billings.component.html",
  styleUrls: ["./billings.component.scss"],
})
export class BillingsComponent {
  selectedRow: IBilling;
  statusList$: Observable<IFilterSelection[]>;
  policyList$: Observable<IFilterSelection[]>;
  billingTypeList$: Observable<IFilterSelection[]>;

  filterProperties: IBillingsFilter = {
    searchValue: "",
    statusList: [],
    policyList: [],
    billingTypeList: [],
    startDate: null,
    endDate: null,
  };
  paginationProperties: IPaginationMetadata = {
    offset: 1,
    limit: 20,
    length: 0,
    sortIndex: 0,
    sortDirection: "desc",
  };

  listingData$ = new BehaviorSubject<IBilling[]>([]);
  filterQuery$ = new BehaviorSubject<IBillingsFilter>(this.filterProperties);
  pagination$ = new BehaviorSubject<IPaginationMetadata>(
    this.paginationProperties,
  );
  _onDestroy = new Subscription();
  apiStatus$ = new BehaviorSubject<TApiStatus>("LOADING");

  // TABLE COLUNS
  displayedColumns: string[] = [
    "date",
    "type",
    "policy",
    "amount",
    "status",
    "remarks",
    "action",
  ];

  constructor(
    private billingsService: BillingsService,
    private bottomSheet: MatBottomSheet,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.resumeFilterQueryParams();
  }

  ngOnInit(): void {
    this.getbillingTypeListDropdown();
    this.getBillingList();
  }

  get showEmptyState() {
    return (
      this.apiStatus$.value === "SUCCESS" &&
      this.listingData$.value.length === 0
    );
  }

  get isLoadingState() {
    return this.apiStatus$.value === "LOADING";
  }

  private getBillingList() {
    this._onDestroy = combineLatest([
      this.pagination$,
      this.filterQuery$.pipe(debounceTime(500)),
    ])
      .pipe(
        switchMap(([pagination, filterQuery]) => {
          this.listingData$.next([]);
          this.apiStatus$.next("LOADING");

          let objRequest = {
            limit: pagination.limit,
            offset: pagination.offset,
            columnIndex: pagination.sortIndex,
            sortDirection: pagination.sortDirection,
            billingTypeList: filterQuery.billingTypeList,
            policyIdList: filterQuery.policyList,
            billPaymentStatusList: filterQuery.statusList,
          };

          return this.billingsService.getBillingLists(objRequest);
        }),
        delay(200),
        tap({
          error: (err) => {
            this.apiStatus$.next("ERROR");
          },
          next: () => {
            this.apiStatus$.next("SUCCESS");
          },
        }),
      )
      .subscribe((response: IBillingResponse) => {
        this.listingData$.next(response.data);

        this.paginationProperties = {
          ...this.paginationProperties,
          length: response.count,
        };
      });
  }

  getbillingTypeListDropdown() {
    this.billingTypeList$ = this.billingsService.getTypeSelection().pipe(
      map((res: string[]) => {
        return res.map((x) => ({ id: x, name: x }));
      }),
      tap(() => {
        this.getStatusListDropdown();
      }),
    );
  }

  getStatusListDropdown() {
    this.statusList$ = this.billingsService.getPaymentStatusSelection().pipe(
      map((res: string[]) => {
        return res.map((x) => ({ id: x, name: x }));
      }),
      tap(() => {
        this.getPolicyDropdown();
      }),
    );
  }

  getPolicyDropdown() {
    this.policyList$ = this.billingsService.getPolicySelection().pipe(
      map((res: { policyId: string; policy: string }[]) => {
        return res.map((x) => ({ id: x.policyId, name: x.policy }));
      }),
    );
  }

  handleFilterChange(event: { list: string[]; filterType: string }) {
    const { list, filterType } = event;

    const {
      searchValue,
      statusList,
      policyList,
      startDate,
      endDate,
      billingTypeList,
    } = this.filterProperties;

    if (filterType === "billingType") {
      this.filterProperties.billingTypeList = list;

      this.filterQuery$.next({
        searchValue,
        statusList,
        policyList,
        startDate,
        endDate,
        billingTypeList: list,
      });
    }

    if (filterType === "status") {
      this.filterProperties.statusList = list;

      this.filterQuery$.next({
        searchValue,
        statusList: list,
        policyList,
        startDate,
        endDate,
        billingTypeList,
      });
    }

    if (filterType === "policy") {
      this.filterProperties.policyList = list;

      this.filterQuery$.next({
        searchValue,
        statusList,
        policyList: list,
        startDate,
        endDate,
        billingTypeList,
      });
    }

    this.applyFilterToRoute();
  }

  handleClearAllFilters() {
    this.resetAllFilterQuery();
  }

  handleRefreshData() {
    window.location.reload();
  }

  handlePageEvent(e: PageEvent) {
    this.paginationProperties = {
      offset: e.pageIndex + 1,
      limit: e.pageSize,
      length: this.paginationProperties.length,
      sortIndex: this.paginationProperties.sortIndex,
      sortDirection: this.paginationProperties.sortDirection,
    };

    this.pagination$.next({ ...this.paginationProperties });

    this.applyFilterToRoute();
  }

  private applyFilterToRoute(): void {
    const {
      searchValue,
      policyList,
      billingTypeList,
      statusList,
      startDate,
      endDate,
    } = this.filterProperties;
    const { limit, offset } = this.paginationProperties;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        search: searchValue ? searchValue : null,
        products: policyList.length ? policyList : [],
        status: statusList.length ? statusList : [],
        billingType: billingTypeList.length ? billingTypeList : [],
        startDate,
        endDate,
        limit,
        offset,
      },
      replaceUrl: true,
    });
  }

  private resumeFilterQueryParams() {
    let queryParams = this.activatedRoute.snapshot.queryParamMap;

    if (queryParams.get("offset")) {
      this.paginationProperties.offset = parseInt(
        queryParams.get("offset") || "1",
      );
    }
    if (queryParams.get("limit")) {
      this.paginationProperties.limit = parseInt(
        queryParams.get("limit") || "5",
      );
    }
    if (queryParams.get("search")) {
      this.filterProperties.searchValue = queryParams.get("search") || "";
    }
    if (queryParams.getAll("status")) {
      this.filterProperties.statusList = queryParams.getAll("status");
    }
    if (queryParams.getAll("products")) {
      this.filterProperties.policyList = queryParams.getAll("products");
    }
    if (queryParams.getAll("products")) {
      this.filterProperties.billingTypeList = queryParams.getAll("billingType");
    }
    if (queryParams.get("startDate")) {
      this.filterProperties.startDate = new Date(
        queryParams.get("startDate") || null || 0,
      );
    }
    if (queryParams.get("endDate")) {
      this.filterProperties.endDate = new Date(
        queryParams.get("endDate") || null || 0,
      );
    }
  }

  private resetAllFilterQuery() {
    this.router
      .navigate([], {
        queryParams: {
          search: undefined,
          status: undefined,
          products: undefined,
          billingType: undefined,
          startDate: undefined,
          endDate: undefined,
        },
        queryParamsHandling: "merge",
      })
      .then(() => {
        window.location.reload();
      });
  }

  getStatusColorMapping(status: string) {
    return getStatusColorMapping(status);
  }

  downloadBillingFile(data: IBilling) {
    const billingDocument = data.billingDocuments[0];
    const docId = billingDocument.billingDocumentId;
    const docName = billingDocument.billingFileName;

    this.billingsService.downloadFileById(docId).subscribe({
      next: (x) => {
        let fileName = docName.split(".")[0];
        FileFunction.downloadFile(x, fileName);
      },
      error: (err) => {},
    });
  }

  downloadPaymentReceiptFile(data: IBilling) {
    console.log(data);
    const docId = data.billPaymentDocumentId;
    const docName = data.billPaymentDocumentFileName;

    this.billingsService.downloadFileById(docId).subscribe({
      next: (x) => {
        let fileName = docName.split(".")[0];
        FileFunction.downloadFile(x, fileName);
      },
      error: (err) => {},
    });
  }

  disableInsurerInvoice() {
    if (
      this.selectedRow?.billingDocuments?.find(
        (x) => x.refType === "InsurerBill",
      )
    )
      return false;

    return true;
  }

  uploadPaymentReceipt(selectedRow?: IBilling) {
    let dialog = this.bottomSheet.open(UploadPaymentReceiptComponent, {
      backdropClass: "sidesheet-container",
      disableClose: true,
      data: {
        id: selectedRow?.billingId,
      },
    });
    dialog.afterDismissed().subscribe((x) => {
      if (x) {
        this.getBillingList();
      }
    });
  }

  viewBillingsPaymentHistory(selectedRow?: IBilling) {
    this.billingsService
      .getStatusHistory(this.selectedRow?.billingId)
      .subscribe({
        next: (x) => {
          let dialog = this.dialog.open(ViewPaymentHistoryDialogComponent, {
            disableClose: true,
            data: {
              id: selectedRow?.billingId,
              list: x,
            },
          });
        },
        error: (err) => {},
      });
  }
}
