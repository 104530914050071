import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-status-tag",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./status-tag.component.html",
  styleUrls: ["./status-tag.component.scss"],
})
export class StatusTagComponent {
  @Input() status: string;
  @Input() color: "grey" | "green" | "sgreen" | "red" | "orange" | "blue" | "";
}
