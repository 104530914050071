import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { Observable } from "rxjs";
import { PageHeaderComponent } from "src/app/shared/components/page-header/page-header.component";
import { SiteHeaderComponent } from "src/app/shared/components/site-header/site-header.component";
import { PoliciesService } from "./_services/policies.service";
import { PolicyCardComponent } from "./ui/components/policy-card/policy-card.component";

@Component({
  selector: "app-policies",
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    PolicyCardComponent,
    MatTableModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatInputModule,
    SiteHeaderComponent,
    CommonModule,
    MatTabsModule,
    FormsModule,
    MatMenuModule,
  ],
  templateUrl: "./policies.component.html",
  styleUrls: ["./policies.component.scss"],
})
export class PoliciesComponent {
  public isLoading: boolean = true;
  public isError: boolean = false;
  public listingData$: Observable<any>;

  data: any = {
    active: [],
    past: [],
  };
  constructor(private policiesService: PoliciesService) {
    this.isError = false;
    this.getData();
  }

  getData() {
    this.policiesService.getPolicyListing().subscribe((res) => {
      this.data = res;
    });
  }
}
