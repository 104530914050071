import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectChange } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { DrawerService } from "src/app/shared/services/drawer.service";
import { IUserCompany } from "src/app/shared/interfaces/user.interface";
import { AuthService } from "src/app/shared/services/auth.service";
import { CompanyStore } from "../../store/company.store";
import { Observable, catchError, takeUntil, tap, throwError } from "rxjs";

// import { UserService } from "src/app/share/service/user.service";
// import { UserDataService } from "src/app/share/data-service/user.data.service";
// import { SELECTED_COMPANY_KEY } from "src/app/share/constants/constants";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
  ],
  templateUrl: "./site-header.component.html",
  styleUrls: ["./site-header.component.scss"],
})
export class SiteHeaderComponent implements OnInit {
  status: any;
  selectedCompanyId: string;
  companies$: Observable<IUserCompany[]>;

  constructor(
    public drawerService: DrawerService, // public UserDataService: UserDataService, // public userService: UserService,
    public authService: AuthService,
    private companyStore: CompanyStore,
  ) {}

  @Output() companySelected = new EventEmitter<string>();

  ngOnInit() {
    this.selectedCompanyId = localStorage.getItem("selectedCompany");
    this.companies$ = this.companyStore.getUserCompanyList();
  }

  changeCompany(e: MatSelectChange) {
    this.selectedCompanyId = e.value;
    this.companySelected.emit(this.selectedCompanyId);

    this.onCompanySelected(e.value);
  }

  onCompanySelected(company: string) {
    this.companyStore
      .switchCompany(company)
      .pipe(
        tap((res) => console.log(res)),
        catchError((err) => {
          const message = "Failed to change company";
          console.error(message, err);
          return throwError(err);
        }),
      )
      .subscribe({ next: (x) => window.location.reload() });
  }
}
