import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IFilterSelection } from "../../interfaces/common.interface";

@Component({
  selector: "app-multi-dropdown",
  standalone: true,
  imports: [
    CommonModule,
    NgxMatSelectSearchModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
  ],
  templateUrl: "./multi-dropdown.component.html",
  styleUrls: ["./multi-dropdown.component.scss"],
})
export class MultiDropdownComponent implements OnInit, OnDestroy {
  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;
  @Input() title: string = "";
  @Input() type: string = "";
  @Input() selectedFilters: any[];
  @Input() options: IFilterSelection[] = []; // Input for the list of options
  @Output() filterFunction = new EventEmitter<any>();

  /** Flag to check if options are fully selected or only some */
  isIndeterminate: boolean = false;
  isChecked: boolean = false;

  protected selectFormCtrl: IFilterSelection[] = [];
  protected multiSelectFilterCtrl: string = "";
  protected filteredOptions: ReplaySubject<IFilterSelection[]> =
    new ReplaySubject<IFilterSelection[]>(1);
  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    /** This code checks if there is any existing filter properties stored in the query params */
    if (this.selectedFilters) {
      const existingSelections = this.options.filter((x) =>
        this.selectedFilters.includes(x.id),
      );
      this.selectFormCtrl = [...existingSelections];

      // this.selectFormCtrl.setValue([...existingSelections]);
      this.isIndeterminateCheck();
    }

    /** This code pushes the options passed from the parent into the dropdown option list */
    this.filteredOptions.next(this.options.slice());

    this.filteredOptions
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (
          a: IFilterSelection,
          b: IFilterSelection,
        ) => a && b && a.id === b.id;
      });
  }

  protected optionChange(value: any) {
    this.isIndeterminateCheck();
    this.filterFunction.emit({
      list: value.map((x) => x.id),
      filterType: this.type,
    });
  }

  protected filterMultiOptions() {
    if (!this.options) {
      return;
    }
    let search = this.multiSelectFilterCtrl;
    if (!search) {
      this.filteredOptions.next(this.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOptions.next(
      this.options.filter(
        (option) => option.name.toLowerCase().indexOf(search) > -1,
      ),
    );
  }

  protected toggleSelectAll(selectAllValue: boolean) {
    this.filteredOptions
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe((val) => {
        if (selectAllValue) {
          this.selectFormCtrl = val;
          this.filterFunction.emit({
            list: val.map((x) => x.id),
            filterType: this.type,
          });
        } else {
          this.selectFormCtrl = [];
          this.filterFunction.emit({
            list: [],
            filterType: this.type,
          });
        }
      });
  }

  protected isIndeterminateCheck() {
    this.isIndeterminate =
      this.selectFormCtrl.length > 0 &&
      this.selectFormCtrl.length < this.options.length;

    this.isChecked =
      this.selectFormCtrl.length > 0 &&
      this.selectFormCtrl.length == this.options.length;
  }

  protected getDataDisplay(selectFormControl: IFilterSelection[]) {
    let values = selectFormControl;

    if (this.options.length === values.length) {
      return `All ${this.title}`;
    }

    if (values.length == 1) {
      return values[0].name;
    } else return `${values.length} item(s) selected`;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
