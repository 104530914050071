<div class="close-btn-wrapper">
  <button mat-icon-button (click)="close($event)">
    <mat-icon>closed</mat-icon>
  </button>
</div>
<form
  class="side-sheet__form form"
  [formGroup]="formGroup"
  (ngSubmit)="submit()"
>
  <ng-container>
    <div class="form__title">Replace payment receipt</div>
  </ng-container>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Payment receipt date</mat-label>
    <input
      matInput
      autocomplete="off"
      (click)="paymentReceiptDate.open()"
      [matDatepicker]="paymentReceiptDate"
      readonly
      formControlName="paymentReceiptDate"
      [max]="today"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="paymentReceiptDate"
    ></mat-datepicker-toggle>
    <mat-datepicker #paymentReceiptDate></mat-datepicker>
    <mat-error>Payment receipt date is required</mat-error>
  </mat-form-field>

  <div class="form__file-input">
    <div class="file-upload__button" (click)="fileInput.click()">
      <p>Upload payment receipt *</p>
      <mat-icon>upload</mat-icon>

      <input
        type="file"
        name="paymentReceiptFile"
        #Image
        accept="application/pdf,image/*"
        style="display: none"
        #fileInput
        (change)="fileChangeEvent($event)"
      />
    </div>
    <div *ngIf="paymentReceiptFile">
      <mat-divider></mat-divider>
      <div class="file-upload__content">
        <span class="mr-sm">
          {{ paymentReceiptFile.name }}
        </span>
        <button
          mat-icon-button
          [disabled]="isLoading"
          (click)="deleteFile('paymentReceiptFile')"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>


  <button
    class="submit-btn"
    [disabled]="formGroup.invalid || !paymentReceiptFile || isLoading"
    type="submit"
    mat-flat-button
  >
    <span>Upload payment receipt</span>
    <mat-icon *ngIf="isLoading"
      ><mat-spinner diameter="18"> </mat-spinner>
    </mat-icon>
  </button>
</form>
