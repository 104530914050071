<app-page-header title="My Policies"></app-page-header>
<div class="my-policy">
  <mat-tab-group
    animationDuration="0ms"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    class="tab-group"
  >
    <mat-tab label="Active Policies">
      <div class="tab-group-content">
        <app-policy-card
          *ngFor="let item of data.active"
          [data]="item"
        ></app-policy-card>
        <div class="not-found" *ngIf="data.active.length === 0">
          <p>No Active Policies Found.</p>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Past Policies">
      <div class="tab-group-content">
        <app-policy-card
          *ngFor="let item of data.past"
          [data]="item"
        ></app-policy-card>
        <div class="not-found" *ngIf="data.active.length === 0">
          <p>No Past Policies Found.</p>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
