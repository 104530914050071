<mat-form-field appearance="outline">
  <mat-label>{{ title }}</mat-label>
  <mat-select
    [(ngModel)]="selectFormCtrl"
    placeholder="{{ title }}"
    [multiple]="true"
    (ngModelChange)="optionChange($event)"
    #multiSelect
  >
    <mat-select-trigger>{{
      getDataDisplay(selectFormCtrl)
    }}</mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [showToggleAllCheckbox]="true"
        [toggleAllCheckboxIndeterminate]="isIndeterminate"
        [toggleAllCheckboxChecked]="isChecked"
        (toggleAll)="toggleSelectAll($event)"
        [(ngModel)]="multiSelectFilterCtrl"
        (ngModelChange)="filterMultiOptions()"
        placeholderLabel="Seach..."
        noEntriesFoundLabel="No match found"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let bank of filteredOptions | async" [value]="bank">
      {{ bank.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
