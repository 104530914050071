<mat-nav-list>
  <div class="close-btn-wrapper">
    <button mat-icon-button (click)="close($event)">
      <mat-icon>closed</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="data.actionType === 'changePassword'">
    <form
      [formGroup]="passwordChangeFormGroup"
      (ngSubmit)="onPasswordChange()"
      class="form"
    >
      <ng-container>
        <div class="form__title">Change Password</div>
      </ng-container>
      <div class="form-field">
        <mat-form-field appearance="outline">
          <mat-label>Current password</mat-label>
          <input
            formControlName="currentPassword"
            matInput
            [type]="hideCurrentPassword ? 'password' : 'text'"
          />
          <!-- Button for Current Password -->
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hideCurrentPassword = !hideCurrentPassword"
          >
            <mat-icon>{{
              hideCurrentPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <!-- New Password Field -->
        <mat-form-field appearance="outline">
          <mat-label>New password</mat-label>
          <input
            formControlName="newPassword"
            matInput
            [type]="hideNewPassword ? 'password' : 'text'"
          />
          <!-- Button for New Password -->
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hideNewPassword = !hideNewPassword"
          >
            <mat-icon>{{
              hideNewPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <!-- Repeat New Password Field -->
        <mat-form-field appearance="outline">
          <mat-label>Repeat new password</mat-label>
          <input
            formControlName="repeatNewPassword"
            matInput
            [type]="hideRepeatNewPassword ? 'password' : 'text'"
          />
          <!-- Button for Repeat New Password -->
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hideRepeatNewPassword = !hideRepeatNewPassword"
          >
            <mat-icon>{{
              hideRepeatNewPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <!-- Submit Button -->

        <button
          class="submit-btn"
          [disabled]="passwordChangeFormGroup.invalid"
          type="submit"
          mat-flat-button
        >
          {{ "UPDATE" }}
        </button>
      </div>
    </form></ng-container
  >
  <ng-container *ngIf="data.actionType === 'update'">
    <form [formGroup]="updateFormGroup" (ngSubmit)="onUpdate()" class="form">
      <ng-container>
        <div class="form__title">Update Profile</div>
      </ng-container>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput placeholder="Email" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Full name</mat-label>
          <input matInput formControlName="fullName" />
          <mat-error> Full name is required </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Position</mat-label>
          <input formControlName="position" matInput />
          <mat-error>Position is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Phone number</mat-label>
          <input formControlName="phoneNumber" matInput />
          <mat-error>Phone number is required</mat-error>
        </mat-form-field>

        <button
          class="submit-btn"
          [disabled]="disabledCondition()"
          type="submit"
          mat-flat-button
        >
          {{ "SUBMIT" }}
        </button>
      </div>
    </form>
  </ng-container>
</mat-nav-list>
