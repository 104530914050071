export const MALAYSIA_STATES = [
	"Wilayah Persekutuan Kuala Lumpur",
	"Johor",
	"Kedah",
	"Kelantan",
	"Melaka",
	"Negeri Sembilan",
	"Pahang",
	"Penang",
	"Perak",
	"Perlis",
	"Sabah",
	"Sarawak",
	"Selangor",
	"Terengganu",
	"Wilayah Persekutuan Labuan",
	"Wilayah Persekutuan Putrajaya",
];