import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  debounceTime,
  delay,
  map,
  of,
  switchMap,
  tap,
} from "rxjs";
import { DateDropdownComponent } from "src/app/shared/components/date-dropdown/date-dropdown.component";
import { EmptyStateComponent } from "src/app/shared/components/empty-state/empty-state.component";
import { MultiDropdownComponent } from "src/app/shared/components/multi-dropdown/multi-dropdown.component";
import { PageHeaderComponent } from "src/app/shared/components/page-header/page-header.component";
import { SiteHeaderComponent } from "src/app/shared/components/site-header/site-header.component";
import { StatusTagComponent } from "src/app/shared/components/status-tag/status-tag.component";
import {
  IFilterSelection,
  IPaginationMetadata,
  TApiStatus,
} from "src/app/shared/interfaces/common.interface";
import { AlertService } from "src/app/shared/services/alert.service";
import { getStatusColorMapping } from "src/app/shared/utils/utils.function";
import {
  IEmployee,
  IEmployeeFilter,
  IEmployeeResponse,
} from "./_interfaces/employees.interface";
import { EmployeesService } from "./_services/employees.service";
import * as moment from "moment";
import { FileFunction } from "src/app/shared/utils/file.function";
import { UploadEmployeeMovementDialogComponent } from "./_components/upload-employee-movement-dialog/upload-employee-movement-dialog.component";
import { DeleteConfirmationDialogComponent } from "./_components/delete-confirmation-dialog/delete-confirmation-dialog.component";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { ViewStatusDialogComponent } from "./_components/view-status-dialog/view-status-dialog.component";

@Component({
  selector: "app-employees",
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    StatusTagComponent,
    MatTableModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatInputModule,
    SiteHeaderComponent,
    TranslateModule,
    FormsModule,
    MatMenuModule,
    NgxSkeletonLoaderModule,
    MultiDropdownComponent,
    MatBottomSheetModule,
    MatTooltipModule,
    EmptyStateComponent,
    DateDropdownComponent,
    DeleteConfirmationDialogComponent,
    MatDialogModule,
  ],
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.scss"],
})
export class EmployeesComponent implements OnInit {
  selectedRow: IEmployee;
  statusList$: Observable<IFilterSelection[]>;
  filterProperties: IEmployeeFilter = {
    searchValue: "",
    statusList: [],
    startDate: null,
    endDate: null,
    date: null,
  };
  paginationProperties: IPaginationMetadata = {
    offset: 1,
    limit: 20,
    length: 0,
    sortIndex: 0,
    sortDirection: "desc",
  };

  listingData$ = new BehaviorSubject<IEmployee[]>([]);
  filterQuery$ = new BehaviorSubject<IEmployeeFilter>(this.filterProperties);
  pagination$ = new BehaviorSubject<IPaginationMetadata>(
    this.paginationProperties,
  );
  _onDestroy = new Subscription();
  apiStatus$ = new BehaviorSubject<TApiStatus>("LOADING");

  // TABLE COLUNS
  displayedColumns: string[] = [
    "createdDate",
    "title",
    "status",
    "statusDatetime",
    "remark",
    "action",
  ];

  constructor(
    private employeesService: EmployeesService,
    private bottomSheet: MatBottomSheet,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.resumeFilterQueryParams();
  }

  ngOnInit(): void {
    this.getStatusListDropdown();
    this.getEmployeeMovementList();
  }

  get showEmptyState() {
    return (
      this.apiStatus$.value === "SUCCESS" &&
      this.listingData$.value.length === 0
    );
  }

  get isLoadingState() {
    return this.apiStatus$.value === "LOADING";
  }

  private getEmployeeMovementList() {
    this._onDestroy = combineLatest([
      this.pagination$,
      this.filterQuery$.pipe(debounceTime(500)),
    ])
      .pipe(
        switchMap(([pagination, filterQuery]) => {
          this.listingData$.next([]);
          this.apiStatus$.next("LOADING");

          let objRequest = {
            text: filterQuery.searchValue,
            limit: pagination.limit,
            offset: pagination.offset,
            columnIndex: pagination.sortIndex,
            sortDirection: pagination.sortDirection,
            startDate: filterQuery.startDate,
            endDate: filterQuery.endDate,
            statusList: filterQuery.statusList,
          };

          return this.employeesService.getEmployeeMovementLists(objRequest);
        }),
        delay(200),
        tap({
          error: (err) => {
            this.apiStatus$.next("ERROR");
          },
          next: () => {
            this.apiStatus$.next("SUCCESS");
          },
        }),
      )
      .subscribe((response: IEmployeeResponse) => {
        this.listingData$.next(response.data);

        this.paginationProperties = {
          ...this.paginationProperties,
          length: response.count,
        };
      });
  }

  getStatusListDropdown() {
    this.statusList$ = of(["New", "Processing", "Completed", "Invalid"]).pipe(
      map((res: string[]) => {
        return res.map((x) => ({ id: x, name: x }));
      }),
    );
  }

  handleFilterChange(event: any) {
    const {
      list,
      filterType,
      startDateFilter,
      endDateFilter,
      selectedDateFilter,
    } = event;

    const { searchValue, startDate, statusList, endDate, date } =
      this.filterProperties;

    if (filterType === "status") {
      this.filterProperties.statusList = list;

      this.filterQuery$.next({
        searchValue,
        statusList: list,
        startDate,
        endDate,
        date,
      });
    }

    if (filterType === "date") {
      let dateType = selectedDateFilter;
      this.filterProperties.startDate = startDateFilter;
      this.filterProperties.endDate = endDateFilter;

      if (startDateFilter === null || endDateFilter === null) {
        dateType = null;
      }

      this.filterProperties.date = dateType;

      this.filterQuery$.next({
        searchValue,
        statusList,
        startDate: startDateFilter,
        endDate: endDateFilter,
        date: dateType,
      });
    }

    this.applyFilterToRoute();
  }

  handleSearchChange(searchQuery: string) {
    const { startDate, endDate, statusList, date } = this.filterProperties;
    this.filterProperties.searchValue = searchQuery;
    this.filterQuery$.next({
      searchValue: searchQuery,
      statusList,
      startDate,
      endDate,
      date,
    });

    this.applyFilterToRoute();
  }

  handleClearSearch() {
    const { startDate, endDate, statusList, date } = this.filterProperties;
    this.filterProperties.searchValue = "";
    this.filterQuery$.next({
      searchValue: "",
      statusList,
      startDate,
      endDate,
      date,
    });

    this.applyFilterToRoute();
  }

  handleClearAllFilters() {
    this.resetAllFilterQuery();
  }

  handleRefreshData() {
    window.location.reload();
  }

  handlePageEvent(e: PageEvent) {
    this.paginationProperties = {
      offset: e.pageIndex + 1,
      limit: e.pageSize,
      length: this.paginationProperties.length,
      sortIndex: this.paginationProperties.sortIndex,
      sortDirection: this.paginationProperties.sortDirection,
    };

    this.pagination$.next({ ...this.paginationProperties });

    this.applyFilterToRoute();
  }

  private applyFilterToRoute(): void {
    const { searchValue, statusList, startDate, endDate } =
      this.filterProperties;
    const { limit, offset } = this.paginationProperties;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        search: searchValue ? searchValue : null,
        status: statusList.length ? statusList : [],
        startDate,
        endDate,
        limit,
        offset,
      },
      replaceUrl: true,
    });
  }

  private resumeFilterQueryParams() {
    let queryParams = this.activatedRoute.snapshot.queryParamMap;

    if (queryParams.get("offset")) {
      this.paginationProperties.offset = parseInt(
        queryParams.get("offset") || "1",
      );
    }
    if (queryParams.get("limit")) {
      this.paginationProperties.limit = parseInt(
        queryParams.get("limit") || "5",
      );
    }
    if (queryParams.get("search")) {
      this.filterProperties.searchValue = queryParams.get("search") || "";
    }
    if (queryParams.getAll("status")) {
      this.filterProperties.statusList = queryParams.getAll("status");
    }
    if (queryParams.get("startDate")) {
      this.filterProperties.startDate = new Date(
        queryParams.get("startDate") || null || 0,
      );
    }
    if (queryParams.get("endDate")) {
      this.filterProperties.endDate = new Date(
        queryParams.get("endDate") || null || 0,
      );
    }
  }

  upload() {
    let dialog = this.bottomSheet.open(UploadEmployeeMovementDialogComponent, {
      backdropClass: "sidesheet-container",
      disableClose: true,
    });

    dialog.afterDismissed().subscribe((x) => {
      if (x) {
        this.getEmployeeMovementList();
        this.alertService.openSnackBar("Successful uploaded");
      }
    });
  }

  downloadDocumentById(filename: any, id: any) {
    this.employeesService.downloadEmployeeMovementDocumentById(id).subscribe({
      next: (x) => {
        let fileName = filename.split(".")[0];
        let dataType = x.type;
        FileFunction.downloadFile(x, fileName);
      },
      error: (err) => {
        this.alertService.openSnackBar(err.message);
      },
    });
  }

  downloadTemplate() {
    this.employeesService.downloadTemplate().subscribe({
      next: (x) => {
        let fileName = "Employees Template " + moment().format("DD/MM/yyyy");
        FileFunction.downloadFile(x, fileName);
      },
      error: (err) => {
        this.alertService.openSnackBar(err.message);
      },
    });
  }

  exportData() {
    this.employeesService.downloadCVVTemplate().subscribe({
      next: (x) => {
        let fileName = "Employees CSV " + moment().format("DD/MM/yyyy");
        let dataType = x.type;
        FileFunction.downloadFile(x, fileName);
      },
      error: (err) => {
        this.alertService.openSnackBar(err.message);
      },
    });
  }

  viewStatusHistory(element: IEmployee) {
    let movementId = element.movementId;

    this.employeesService.getStatusHistoryById(movementId).subscribe({
      next: (x) => {
        let dialogRef = this.dialog.open(ViewStatusDialogComponent, {
          data: {
            title: x.title,
            fileName: element.fileName,
            historyStatus: x.historyList,
          },
          disableClose: true,
        });
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  deleteNewRecord(element: IEmployee) {
    let movementId = element.movementId;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: element,
    });
    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        this.employeesService.deleteEmployeeMovementById(movementId).subscribe({
          next: (x) => {
            this.getEmployeeMovementList();
            this.alertService.openSnackBar(`Successfully Deleted the record`);
          },
          error: (err) => {
            this.alertService.openSnackBar(`Error: Unable to delete item`);
          },
        });
      }
    });
  }

  private resetAllFilterQuery() {
    this.router
      .navigate([], {
        queryParams: {
          search: undefined,
          status: undefined,
          products: undefined,
          billingType: undefined,
          startDate: undefined,
          endDate: undefined,
        },
        queryParamsHandling: "merge",
      })
      .then(() => {
        window.location.reload();
      });
  }

  getStatusColorMapping(status: string) {
    return getStatusColorMapping(status);
  }
}
