import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PageHeaderComponent } from "src/app/shared/components/page-header/page-header.component";
import { StatusTagComponent } from "src/app/shared/components/status-tag/status-tag.component";
import { IPaginationMetadata } from "src/app/shared/interfaces/common.interface";
import {
  FormattedUserData,
  IUserProfile,
} from "./_interfaces/profile.interface";
import { UserService } from "./_services/profile.service";
import { ActionForm } from "./ui/components/action-form/action-form.component";
@Component({
  selector: "app-profile",
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    PageHeaderComponent,
    PageHeaderComponent,
    FormsModule,
    MatMenuModule,
    MatBottomSheetModule,
    StatusTagComponent,
  ],

  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent {
  public isLoading: boolean = false;
  public isError: boolean = false;
  public productList: any[] = [];
  public statusList: string[] = [];
  public listingData$: Observable<any>;

  // TABLE COLUMNS
  displayedColumns: string[] = [
    "email",
    "fullName",
    "position",
    "contact",
    "status",
    "dateUpdated",
    "lastLoginDate",
  ];
  userData: IUserProfile = {} as IUserProfile;
  text = "";
  pagination: IPaginationMetadata = {
    sortIndex: 0,
    limit: 50,
    offset: 1,
    sortDirection: "desc",
    length: 0,
  };
  public userData$: Observable<FormattedUserData[]>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.getProfileInfo();
    this.getOrganizationList();
  }
  getProfileInfo() {
    this.userData$ = this.userService.getProfileInfo().pipe(
      map((userData: IUserProfile) => {
        this.userData = userData;
        return [
          { label: "Account Status", value: userData.accountStatus },
          { label: "Account Type", value: userData.accountType },
          { label: "Company Name", value: userData.companyName },
          { label: "Email", value: userData.email },
          { label: "Full Name", value: userData.fullName },
          { label: "Phone Number", value: userData.phoneNumber },
          { label: "Position", value: userData.position },
        ];
      }),
    );
  }

  getOrganizationList() {
    this.listingData$ = this.userService
      .getOrganizationList(this.pagination, this.text)
      .pipe(
        map((data) => {
          console.log(data);
          this.pagination.length = data.count;
          return data;
        }),
      );
  }
  getStatusColorMapping(status: string) {
    switch (status) {
      case "Active":
        return "green";
      case "Pending":
        return "orange";
      case "Inactive":
        return "red";
      default:
        return "";
    }
  }
  handleAction(actionType: string): void {
    this._bottomSheet.open(ActionForm, {
      data: {
        actionType: actionType,
        userData: this.userData,
        callback: this.refreshData.bind(this),
      },
      hasBackdrop: true,
      backdropClass: "sidesheet-container",
    });
  }
  refreshData(): void {
    this.getProfileInfo();
    this.getOrganizationList();
  }
  handlePageEvent(e: PageEvent) {
    this.pagination = {
      ...this.pagination,
      limit: e.pageSize,
      offset: e.pageIndex + 1,
    };
    this.getOrganizationList();
  }
}
