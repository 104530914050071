<div class="policy-card">
  <div class="policy-card--tag">O/S | RM 1,000.00</div>
  <div class="policy-card--header">
    <div class="policy-card--header-left">
      <img src="{{ data.image }}" />
      <div>
        <h3>{{ data.policyName }}</h3>
        <button
          mat-icon-button
          matTooltip="View Actions"
          [matMenuTriggerFor]="menu"
          aria-label="Actions"
          class="policy-card--header-button-mobile"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>

    <button
      mat-icon-button
      matTooltip="View Actions"
      [matMenuTriggerFor]="menu"
      aria-label="Actions"
      class="policy-card--header-button"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
  <div class="policy-card--body">
    <div class="w-full">
      <div class="flex flex-col mb-sm">
        <span class="policy-card--body-label">Policy Name</span>
        <span class="policy-card--body-value">
          {{ data.policyName }}
        </span>
      </div>

      <div class="flex flex-col mb-sm">
        <span class="policy-card--body-label">Policy Number</span>
        <span class="policy-card--body-value">
          {{ data.policyNo }}
        </span>
      </div>

      <div class="flex flex-col mb-sm">
        <span class="policy-card--body-label">Coverage Period</span>
        <span class="policy-card--body-value">
          {{
            (data.startDate | date : "dd MMM yyyy") +
              " - " +
              (data.endDate | date : "dd MMM yyyy")
          }}
        </span>
      </div>
    </div>

    <div class="w-full">
      <div class="flex flex-col mb-sm">
        <span class="policy-card--body-label">Payment Type</span>
        <span class="policy-card--body-value">
          {{ data.paymentType }}
        </span>
      </div>

      <div class="flex flex-col mb-sm">
        <span class="policy-card--body-label">TPA</span>
        <span class="policy-card--body-value">
          {{ data.tpaName }}
        </span>
      </div>

      <div class="flex flex-col mb-sm">
        <span class="policy-card--body-label">Hotline</span>
        <span class="policy-card--body-value">
          {{ data.hotline }}
        </span>
      </div>
    </div>
  </div>
</div>

<!-- ## TEMPLATES -->

<!-- Menu Dropdown -->
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    (click)="onDownloadClick(data.pds)"
    [disabled]="!data.pds"
  >
    <mat-icon>download</mat-icon> <span>Product Disclosure Sheet</span>
  </button>
  <button
    mat-menu-item
    (click)="onDownloadClick(data.pw)"
    [disabled]="!data.pw"
  >
    <mat-icon>download</mat-icon> <span>Policy Wording</span>
  </button>
  <button
    mat-menu-item
    (click)="onDownloadClick(data.faq)"
    [disabled]="!data.faq"
  >
    <mat-icon>download</mat-icon> <span>Brief/FAQs</span>
  </button>
</mat-menu>
