import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { IPaginationMetadata } from "src/app/shared/interfaces/common.interface";
import { Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UserService {
  private basePath: string = `${environment.url}/api/EmployeeProfile/`;
  private refreshSource = new Subject<void>();

  header = {
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  getProfileInfo() {
    return this.http.get<any>(`${this.basePath}GetProfileInfo`);
  }

  getOrganizationList(pagination: IPaginationMetadata, text: string) {
    return this.http.post<any>(`${this.basePath}GetOrganizationList`, {
      ...pagination,
      text,
    });
  }

  updateProfileInfo(data: any) {
    return this.http.post<any>(`${this.basePath}UpdateProfileInfo`, {
      ...data,
    });
  }
  updateAccountPassword(data: any) {
    return this.http.post<any>(`${this.basePath}UpdateAccountPassword`, {
      ...data,
    });
  }
}
