import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, shareReplay, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  IAuthCredentials,
  ILoginCredentials,
  IUserCompany,
  IUserLogin,
} from "../interfaces/user.interface";
import { api } from "../constants/api/api-url";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  basePath: string = environment.url;
  private tokenKey = "token";

  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    document.cookie = `${this.tokenKey}=${token};path=/;`;
  }

  logout() {
    localStorage.clear();
    document.cookie = `${this.tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    window.location.reload();
  }

  getToken() {
    return this.getCookie(this.tokenKey);
  }

  getUserId() {
    let token = this.getToken();
    let decoded = jwtDecode(token);
    return decoded[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
    ];
  }

  isTokenExpired(): boolean {
    let token = this.getToken();
    if (!token) return false;
    let decoded = jwtDecode(token);
    let expireTime = new Date(decoded["exp"] * 1000);

    return expireTime < new Date();
  }

  isLoggedIn(): boolean {
    if (this.getToken()) {
      return !this.isTokenExpired();
    }
    return false;
  }

  private getCookie(cname): string | null {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  loginToGetCompanySelection(
    email: string,
    password: string,
  ): Observable<IUserCompany[]> {
    return this.http
      .post<{ companies: IUserCompany[] }>(
        this.basePath + api.GET_USER_COMPANY_LIST,
        { email, password },
      )
      .pipe(
        map((res) => res.companies),
        shareReplay(),
      );
  }

  loginWithCompanyId(payload: ILoginCredentials): Observable<IAuthCredentials> {
    return this.http.post<IAuthCredentials>(this.basePath + api.LOGIN, payload);
  }

  sendForgotPasswordEmail(objRequest: any, options?: any): Observable<any> {
    return this.http.post(
      this.basePath + api.SEND_FORGOT_PASSWORD_EMAIL,
      objRequest,
      { headers: this.headers, responseType: "text" },
    );
  }

  resetPassword(key: any, objRequest: any, options?: any): Observable<any> {
    return this.http.post(
      this.basePath + api.RESET_PASSWORD + "?key=" + key,
      objRequest,
      { headers: this.headers, responseType: "text" },
    );
  }

  resetPasswordLinkVerify(
    key: any,
    objRequest?: any,
    options?: any,
  ): Observable<any> {
    return this.http.get(
      this.basePath + api.RESET_PASSWORD_LINK_VERIFY + "?key=" + key,
      key,
    );
  }

  firstTimeLoginVerification(key: string): Observable<IUserLogin> {
    return this.http
      .get<IUserLogin>(
        `${this.basePath + api.FIRST_TIME_LOGIN_VERIFY}?key=${key}`,
      )
      .pipe(shareReplay());
  }

  firstTimeLogin(key: any, objRequest: any, options?: any): Observable<any> {
    return this.http.post(
      this.basePath + api.FIRST_TIME_LOGIN + "?key=" + key,
      objRequest,
      { headers: this.headers, responseType: "text" },
    );
  }
}
