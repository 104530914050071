<app-page-header title="Employee Movement">
  <!-- ## TOOLBAR SECTION -->

  <section class="toolbar-section">
    <button mat-flat-button color="primary" (click)="upload()">
      <mat-icon>upload</mat-icon>Upload
    </button>
    <button mat-stroked-button (click)="downloadTemplate()">
      <mat-icon>download</mat-icon>Download template
    </button>
    <button mat-stroked-button (click)="exportData()">Export</button>
    <button mat-stroked-button (click)="handleRefreshData()">
      <mat-icon>refresh</mat-icon>Refresh
    </button>
  </section>
</app-page-header>

<main class="listing-page__content">
  <ng-container
    *ngIf="(apiStatus$ | async) === 'ERROR'; then errorMessage"
  ></ng-container>
  <!-- ## TABLE TOOLBAR SECTION -->
  <section class="table-toolbar">
    <div class="table-toolbar__filters">
      <mat-form-field appearance="outline" style="width: 200px">
        <mat-label>Search</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="filterProperties.searchValue"
          (ngModelChange)="handleSearchChange($event)"
        />
        <button
          *ngIf="filterProperties.searchValue.length"
          matSuffix
          mat-icon-button
          (click)="handleClearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <ng-container *ngIf="statusList$ | async as statusList">
        <app-multi-dropdown
          type="status"
          title="Status"
          [selectedFilters]="filterProperties.statusList"
          [options]="statusList"
          (filterFunction)="handleFilterChange($event)"
        ></app-multi-dropdown
      ></ng-container>

      <app-date-dropdown
        [startDate]="filterProperties.startDate"
        [endDate]="filterProperties.endDate"
        [selectedFilters]="filterProperties.date"
        (filterFunction)="handleFilterChange($event)"
      ></app-date-dropdown>

      <!-- Clear all filters button -->
      <button
        mat-button
        [disabled]="isLoadingState"
        *ngIf="filterProperties.statusList.length"
        (click)="handleClearAllFilters()"
      >
        Clear all filters
      </button>
    </div>
  </section>

  <!-- ## TABLE SECTION -->

  <section class="table-container">
    <div
      class="table-listing-wrapper"
      *ngIf="!((apiStatus$ | async) === 'ERROR')"
    >
      <table mat-table [dataSource]="(listingData$ | async)!">
        <!-- Date Joined Column -->
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef>Submitted date</th>
          <td mat-cell mat-column-date *matCellDef="let element">
            {{ element.createdDate | date : "dd MMM yyyy HH:mm" }}
          </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>File</th>
          <td mat-cell mat-column-type *matCellDef="let element">
            <span
              class="download-link"
              (click)="
                downloadDocumentById(element.fileName, element.documentId)
              "
              >{{ element.title }}</span
            >
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell mat-column-status *matCellDef="let element">
            <app-status-tag
              [status]="element.status"
              [color]="getStatusColorMapping(element.status)"
            ></app-status-tag>
          </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="statusDatetime">
          <th mat-header-cell *matHeaderCellDef>Date updated</th>
          <td mat-cell mat-column-date *matCellDef="let element">
            {{ element.statusDatetime | date : "dd MMM yyyy HH:mm" }}
          </td>
        </ng-container>

        <!-- Remarks Column -->
        <ng-container matColumnDef="remark">
          <th mat-header-cell *matHeaderCellDef>Remarks</th>
          <td mat-cell mat-column-remarks *matCellDef="let element">
            {{ element.remark }}
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell mat-column-action *matCellDef="let element">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{ element: element }"
              (click)="selectedRow = element; $event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length" *ngIf="isLoadingState">
            <ngx-skeleton-loader
              count="5"
              [theme]="{
                height: '40px',
                'animation-duration': '1s'
              }"
            >
            </ngx-skeleton-loader>
          </td>
          <td *ngIf="showEmptyState" [attr.colspan]="displayedColumns.length">
            <app-empty-state></app-empty-state>
          </td>
        </tr>
      </table>

      <ng-container *ngIf="paginationProperties">
        <mat-paginator
          [disabled]="isLoadingState"
          [pageSizeOptions]="[5, 10, 20, 50, 100]"
          [length]="paginationProperties.length"
          [pageSize]="paginationProperties.limit"
          [pageIndex]="paginationProperties.offset - 1"
          (page)="handlePageEvent($event)"
        >
        </mat-paginator
      ></ng-container>
    </div>
  </section>
</main>

<!-- ## TEMPLATES -->

<!-- Error message -->
<ng-template #errorMessage>
  <div class="error-message">
    <mat-icon>error</mat-icon>
    <span
      >Uh oh! We are unable to retrieve your data. Please refresh. If the issue
      persists, kindly contact our support team.</span
    >
  </div>
</ng-template>

<mat-menu #menu="matMenu" class="mat-elevation-z2">
  <ng-template matMenuContent let-element="element">
    <div class="flex flex-col">
      <button
        mat-button
        class="menu-button"
        (click)="viewStatusHistory(element)"
      >
        <mat-icon>remove_red_eye</mat-icon>View status
      </button>
      <button
        mat-button
        color="warn"
        class="menu-button"
        *ngIf="element.status === 'New'"
        (click)="deleteNewRecord(element)"
      >
        <mat-icon>delete</mat-icon>Delete
      </button>
    </div>
  </ng-template>
</mat-menu>
