<header>
  <div class="header-left">
    <button
      class="header-icon"
      mat-icon-button
      (click)="drawerService.toggle()"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <img class="header-logo" src="../../../assets/images/ps-business.png" />
  </div>
  <div class="header-right">
    <ng-container *ngIf="companies$ | async as companyList">
      <mat-form-field *ngIf="companyList?.length > 1" appearance="outline">
        <mat-select
          [(value)]="selectedCompanyId"
          (selectionChange)="changeCompany($event)"
        >
          <mat-option *ngFor="let company of companyList" [value]="company.id">
            {{ company.name }}
          </mat-option>
        </mat-select>
      </mat-form-field></ng-container
    >

    <button
      mat-icon-button
      class="header-icon avatar"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>person</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="authService.logout()">Logout</button>
    </mat-menu>
  </div>
</header>
