import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import * as moment from "moment";
import { MatNativeDateModule } from "@angular/material/core";

@Component({
  selector: "app-date-dropdown",
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatNativeDateModule,
  ],
  templateUrl: "./date-dropdown.component.html",
  styleUrls: ["./date-dropdown.component.scss"],
})
export class DateDropdownComponent implements OnInit {
  @Output() filterFunction = new EventEmitter<any>();
  @Input() selectedFilters: any;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() resetTrigger: boolean;

  minFilterEndDate: Date;

  filterSelected: "today" | "week" | "month" | "year" | "custom" | null = null;

  constructor() {}

  ngOnInit(): void {
    if (this.selectedFilters) {
      this.filterSelected = this.selectedFilters;

      this.startDateClose();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedFilters || changes.startDate || changes.endDate) {
      this.filterSelected = this.selectedFilters;
      this.startDate = changes.startDate?.currentValue || null;
      this.endDate = changes.endDate?.currentValue || null;
      this.startDateClose();
    }
    if (changes.resetTrigger && changes.resetTrigger.currentValue) {
      this.resetDate();
      this.filterSelected = null;
    }
  }

  private filterDate() {
    this.filterFunction.emit({
      filterType: "date",
      selectedDateFilter: this.filterSelected,
      startDateFilter: this.startDate,
      endDateFilter: this.endDate,
    });
  }

  setTodayDate(hasFilter: boolean = true) {
    this.startDate = new Date();
    this.endDate = new Date();
    this.setDefaultTimeRange();
    if (hasFilter) {
      this.filterDate();
    }
  }

  setCurrentWeekDate() {
    this.startDate = moment().startOf("week").toDate();
    this.endDate = moment().endOf("week").toDate();
    this.setDefaultTimeRange();
    this.filterDate();
  }

  setCurrentMonthDate() {
    this.startDate = moment().startOf("month").toDate();
    this.endDate = moment().endOf("month").toDate();
    this.setDefaultTimeRange();
    this.filterDate();
  }

  setCurrentYearDate() {
    this.startDate = moment().startOf("year").toDate();
    this.endDate = moment().endOf("year").toDate();
    this.setDefaultTimeRange();
    this.filterDate();
  }

  resetDate() {
    this.startDate = null;
    this.endDate = null;
    this.filterDate();
  }

  onStartDateSelected(e) {
    this.startDate = e.target.value;
  }

  onEndDateSelected(e) {
    this.endDate = new Date(e.target.value.setHours(23, 59, 59, 999));
  }

  onDateRangePickerClosed() {
    if (this.startDate && this.endDate) {
      this.filterDate();
    } else {
      this.filterSelected = null;
    }
  }

  startDateOpen() {
    this.startDate = null;
    this.endDate = null;
  }

  startDateClose() {
    if (this.startDate == null) {
      switch (this.filterSelected) {
        case "today":
          this.setTodayDate();
          break;
        case "week":
          this.setCurrentWeekDate();
          break;
        case "month":
          this.setCurrentMonthDate();
          break;
        case "year":
          this.setCurrentYearDate();
          break;
      }
    }
  }

  endDateClose() {
    if (this.endDate == null) {
      this.endDate = this.startDate;
    }
    this.filterDate();
  }

  resetDateFilter() {
    event.stopImmediatePropagation();
    this.resetDate();
    this.filterSelected = null;
  }

  private setDefaultTimeRange() {
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 999);
  }
}
