import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	baseUrl = "";
	constructor(private http: HttpClient) {}

	get(endpoint: string, params?: any, options?: any) {
		return this.http.get(this.baseUrl + endpoint, options);
	}

	post(endpoint: string, params: any, options?: any) {
		return this.http.post(this.baseUrl + endpoint, params, options);
	}

	patch(endpoint: string, params: any, options?: any) {
		return this.http.patch(this.baseUrl + endpoint, params, options);
	}

	download(endpoint: string, params: any, options?: any) {
		options = {
			responseType: "blob",
		};

		return this.http.post(this.baseUrl + endpoint, params, options);
	}

	put(endpoint: string, params: any, options?: any) {
		return this.http.put(this.baseUrl + endpoint, params, options);
	}

	getDocument(endpoint: string, params?: any, options?: any) {
		options = {
			responseType: "blob",
		};
		return this.http.get(this.baseUrl + endpoint, options);
	}
}
