import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[phoneNumberOnly]",
  standalone: true,
})
export class PhoneNumberOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event"]) onInputChange(event: any) {
    const inputValue = this.el.nativeElement.value;
    const sanitizedValue = inputValue.replace(/[^0-9+]/g, "");

    // Restrict length to a maximum of 14 characters
    if (sanitizedValue.length > 14) {
      this.el.nativeElement.value = sanitizedValue.slice(0, 14);
    } else {
      this.el.nativeElement.value = sanitizedValue;
    }

    // If inputValue was modified, stop propagation
    if (inputValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  @HostListener("keypress", ["$event"]) onKeyPress(event: KeyboardEvent) {
    const allowedChars = /[0-9+]/;
    const inputChar = String.fromCharCode(event.charCode);

    // Prevent input if character is not allowed
    if (!allowedChars.test(inputChar)) {
      event.preventDefault();
    }
  }
}
