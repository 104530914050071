<app-page-header title="Rewards+"> </app-page-header>
<div class="rewards">
  <div class="rewards-content mb-md">
    <div>
      Stay updated with our Rewards+ program and enjoy a wide range of perks and
      benefits!
    </div>
    <button mat-flat-button class="btn-primary-stroked" (click)="getRewards()">
      Get Rewarded Now
    </button>
  </div>

  <mat-divider></mat-divider>

  <mat-tab-group
    animationDuration="0ms"
    no
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    class="tab-group mt-lg"
    [selectedIndex]="currentTabIndex"
    (selectedTabChange)="tabChanged($event)"
  >
    <mat-tab label="Event">
      <div class="tab-group-content">
        <div class="tab-group-content-header">
          <p>
            Request a new event to enhance employee wellbeing. Click the button
            to submit your event request.
          </p>
          <button
            mat-stroked-button
            class="btn-primary"
            (click)="requestQuotation()"
          >
            Request New Event
          </button>
        </div>
        <main class="listing-page__content">
          <ng-container
            *ngIf="(apiStatus$ | async) === 'ERROR'; then errorMessage"
          ></ng-container>
          <!-- ## TABLE TOOLBAR SECTION -->
          <section class="table-toolbar">
            <div class="table-toolbar__filters">
              <mat-form-field appearance="outline" style="width: 400px">
                <mat-label>Search</mat-label>
                <input
                  matInput
                  type="text"
                  [(ngModel)]="filterProperties.searchValue"
                  (ngModelChange)="handleSearchChange($event)"
                />
                <button
                  *ngIf="filterProperties.searchValue.length"
                  matSuffix
                  mat-icon-button
                  (click)="handleClearSearch()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <app-date-dropdown
                [startDate]="filterProperties.startDate"
                [endDate]="filterProperties.endDate"
                [selectedFilters]="filterProperties.date"
                (filterFunction)="handleFilterChange($event)"
              ></app-date-dropdown>

              <!-- Clear all filters button -->
              <button
                mat-button
                [disabled]="isLoadingState"
                *ngIf="filterProperties.startDate || filterProperties.endDate"
                (click)="handleClearAllFilters()"
              >
                Clear all filters
              </button>
            </div>
          </section>

          <!-- ## TABLE SECTION -->

          <section class="table-container">
            <div
              class="table-listing-wrapper"
              *ngIf="!((apiStatus$ | async) === 'ERROR')"
            >
              <table mat-table [dataSource]="(listingData$ | async)!">
                <!-- Submission Date Column -->
                <ng-container matColumnDef="submissionDate">
                  <th mat-header-cell *matHeaderCellDef>Submitted date</th>
                  <td mat-cell mat-column-date *matCellDef="let list">
                    {{ list.submissionDate | date : "dd MMM yyyy HH:mm" }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell mat-column-date *matCellDef="let list">
                    {{ list.name || "-" }}
                  </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Email</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.email }}
                  </td>
                </ng-container>

                <!-- Headcount Column -->
                <ng-container matColumnDef="headcount">
                  <th mat-header-cell *matHeaderCellDef>Headcount</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.headcount }}
                  </td>
                </ng-container>

                <!-- Total Hours Column -->
                <ng-container matColumnDef="totalHours">
                  <th mat-header-cell *matHeaderCellDef>Total Hours</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.totalHours }}
                  </td>
                </ng-container>
                <!-- Event Date Column -->
                <ng-container matColumnDef="dateOfEvent">
                  <th mat-header-cell *matHeaderCellDef>Date Of Event</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.eventDate | date : "dd MMM yyyy" }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="eventColumnConfig"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: eventColumnConfig"
                ></tr>
                <tr *matNoDataRow>
                  <td
                    [attr.colspan]="eventColumnConfig.length"
                    *ngIf="isLoadingState"
                  >
                    <ngx-skeleton-loader
                      count="5"
                      [theme]="{
                        height: '40px',
                        'animation-duration': '1s'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </td>
                  <td
                    *ngIf="showEmptyState"
                    [attr.colspan]="eventColumnConfig.length"
                  >
                    <app-empty-state></app-empty-state>
                  </td>
                </tr>
              </table>

              <ng-container *ngIf="paginationProperties">
                <mat-paginator
                  [disabled]="isLoadingState"
                  [pageSizeOptions]="[5, 10, 20, 50, 100]"
                  [length]="paginationProperties.length"
                  [pageSize]="paginationProperties.limit"
                  [pageIndex]="paginationProperties.offset - 1"
                  (page)="handlePageEvent($event)"
                >
                </mat-paginator
              ></ng-container>
            </div>
          </section>
        </main>
      </div>
    </mat-tab>
    <mat-tab label="Blood Test Quotation">
      <div class="tab-group-content">
        <div class="tab-group-content-header">
          <p>
            Request a new event to enhance employee wellbeing. Click the button
            to submit your event request.
          </p>
          <button
            mat-stroked-button
            class="btn-primary"
            (click)="requestQuotation()"
          >
            Request New Event
          </button>
        </div>
        <main class="listing-page__content">
          <ng-container
            *ngIf="(apiStatus$ | async) === 'ERROR'; then errorMessage"
          ></ng-container>
          <!-- ## TABLE TOOLBAR SECTION -->
          <section class="table-toolbar">
            <div class="table-toolbar__filters">
              <mat-form-field appearance="outline" style="width: 400px">
                <mat-label>Search</mat-label>
                <input
                  matInput
                  type="text"
                  [(ngModel)]="filterProperties.searchValue"
                  (ngModelChange)="handleSearchChange($event)"
                />
                <button
                  *ngIf="filterProperties.searchValue.length"
                  matSuffix
                  mat-icon-button
                  (click)="handleClearSearch()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <app-date-dropdown
                [startDate]="filterProperties.startDate"
                [endDate]="filterProperties.endDate"
                [selectedFilters]="filterProperties.date"
                (filterFunction)="handleFilterChange($event)"
              ></app-date-dropdown>

              <!-- Clear all filters button -->
              <button
                mat-button
                [disabled]="isLoadingState"
                *ngIf="filterProperties.startDate || filterProperties.endDate"
                (click)="handleClearAllFilters()"
              >
                Clear all filters
              </button>
            </div>
          </section>

          <!-- ## TABLE SECTION -->

          <section class="table-container">
            <div
              class="table-listing-wrapper"
              *ngIf="!((apiStatus$ | async) === 'ERROR')"
            >
              <table mat-table [dataSource]="(listingData$ | async)!">
                <!-- Submission Date Column -->
                <ng-container matColumnDef="submissionDate">
                  <th mat-header-cell *matHeaderCellDef>Submitted date</th>
                  <td mat-cell mat-column-date *matCellDef="let list">
                    {{ list.submissionDate | date : "dd MMM yyyy HH:mm" }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell mat-column-date *matCellDef="let list">
                    {{ list.name || "-" }}
                  </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Email</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.email }}
                  </td>
                </ng-container>

                <!-- Headcount Column -->
                <ng-container matColumnDef="headcount">
                  <th mat-header-cell *matHeaderCellDef>Headcount</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.headcount }}
                  </td>
                </ng-container>

                <!-- Event Date Column -->
                <ng-container matColumnDef="dateOfEvent">
                  <th mat-header-cell *matHeaderCellDef>Date Of Event</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.eventDate | date : "dd MMM yyyy" }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="bloodTestColumnConfig"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: bloodTestColumnConfig"
                ></tr>
                <tr *matNoDataRow>
                  <td
                    [attr.colspan]="eventColumnConfig.length"
                    *ngIf="isLoadingState"
                  >
                    <ngx-skeleton-loader
                      count="5"
                      [theme]="{
                        height: '40px',
                        'animation-duration': '1s'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </td>
                  <td
                    *ngIf="showEmptyState"
                    [attr.colspan]="eventColumnConfig.length"
                  >
                    <app-empty-state></app-empty-state>
                  </td>
                </tr>
              </table>

              <ng-container *ngIf="paginationProperties">
                <mat-paginator
                  [disabled]="isLoadingState"
                  [pageSizeOptions]="[5, 10, 20, 50, 100]"
                  [length]="paginationProperties.length"
                  [pageSize]="paginationProperties.limit"
                  [pageIndex]="paginationProperties.offset - 1"
                  (page)="handlePageEvent($event)"
                >
                </mat-paginator
              ></ng-container>
            </div>
          </section>
        </main>
      </div>
    </mat-tab>
    <mat-tab label="Vaccination Quotation">
      <div class="tab-group-content">
        <div class="tab-group-content-header">
          <p>
            Request a new event to enhance employee wellbeing. Click the button
            to submit your event request.
          </p>
          <button
            mat-stroked-button
            class="btn-primary"
            (click)="requestQuotation()"
          >
            Request New Event
          </button>
        </div>
        <main class="listing-page__content">
          <ng-container
            *ngIf="(apiStatus$ | async) === 'ERROR'; then errorMessage"
          ></ng-container>
          <!-- ## TABLE TOOLBAR SECTION -->
          <section class="table-toolbar">
            <div class="table-toolbar__filters">
              <mat-form-field appearance="outline" style="width: 400px">
                <mat-label>Search</mat-label>
                <input
                  matInput
                  type="text"
                  [(ngModel)]="filterProperties.searchValue"
                  (ngModelChange)="handleSearchChange($event)"
                />
                <button
                  *ngIf="filterProperties.searchValue.length"
                  matSuffix
                  mat-icon-button
                  (click)="handleClearSearch()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <app-date-dropdown
                [startDate]="filterProperties.startDate"
                [endDate]="filterProperties.endDate"
                [selectedFilters]="filterProperties.date"
                (filterFunction)="handleFilterChange($event)"
              ></app-date-dropdown>

              <!-- Clear all filters button -->
              <button
                mat-button
                [disabled]="isLoadingState"
                *ngIf="filterProperties.startDate || filterProperties.endDate"
                (click)="handleClearAllFilters()"
              >
                Clear all filters
              </button>
            </div>
          </section>

          <!-- ## TABLE SECTION -->

          <section class="table-container">
            <div
              class="table-listing-wrapper"
              *ngIf="!((apiStatus$ | async) === 'ERROR')"
            >
              <table mat-table [dataSource]="(listingData$ | async)!">
                <!-- Submission Date Column -->
                <ng-container matColumnDef="submissionDate">
                  <th mat-header-cell *matHeaderCellDef>Submitted date</th>
                  <td mat-cell mat-column-date *matCellDef="let list">
                    {{ list.submissionDate | date : "dd MMM yyyy HH:mm" }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell mat-column-date *matCellDef="let list">
                    {{ list.name || "-" }}
                  </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Email</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.email }}
                  </td>
                </ng-container>

                <!-- Headcount Column -->
                <ng-container matColumnDef="headcount">
                  <th mat-header-cell *matHeaderCellDef>Headcount</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.headcount }}
                  </td>
                </ng-container>

                <!-- Event Date Column -->

                <ng-container matColumnDef="dateOfEvent">
                  <th mat-header-cell *matHeaderCellDef>Date Of Event</th>
                  <td mat-cell mat-column-remarks *matCellDef="let list">
                    {{ list.eventDate | date : "dd MMM yyyy" }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="bloodTestColumnConfig"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: bloodTestColumnConfig"
                ></tr>
                <tr *matNoDataRow>
                  <td
                    [attr.colspan]="eventColumnConfig.length"
                    *ngIf="isLoadingState"
                  >
                    <ngx-skeleton-loader
                      count="5"
                      [theme]="{
                        height: '40px',
                        'animation-duration': '1s'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </td>
                  <td
                    *ngIf="showEmptyState"
                    [attr.colspan]="eventColumnConfig.length"
                  >
                    <app-empty-state></app-empty-state>
                  </td>
                </tr>
              </table>

              <ng-container *ngIf="paginationProperties">
                <mat-paginator
                  [disabled]="isLoadingState"
                  [pageSizeOptions]="[5, 10, 20, 50, 100]"
                  [length]="paginationProperties.length"
                  [pageSize]="paginationProperties.limit"
                  [pageIndex]="paginationProperties.offset - 1"
                  (page)="handlePageEvent($event)"
                >
                </mat-paginator
              ></ng-container>
            </div>
          </section>
        </main>
      </div>
    </mat-tab>
    <mat-tab label="Promotion">
      <div class="tab-group-content">
        <div class="promotion">
          <img
            src="https://www.policystreet.com/wp-content/uploads/JobStreet-eDM-PolicyStreet.jpg"
            class="promotion__img"
            alt=""
          />
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- Error message -->
<ng-template #errorMessage>
  <div class="error-message">
    <mat-icon>error</mat-icon>
    <span>Uh oh! We are unable to retrieve your data. Please refresh.</span>
  </div>
</ng-template>

<!-- Loading message -->
<ng-template #loadingMessage>
  <div class="info-message">
    <!-- <mat-spinner diameter="16"></mat-spinner> -->
    <span>Loading...</span>
  </div>
</ng-template>
