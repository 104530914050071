import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { IRewardsRequestForm } from "../../../shared/interfaces/rewards.interface";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RewardsService {
  private basePath: string = `${environment.url}/api/EmployeeRewardPlus/`;
  private submissionStatusSubject = new BehaviorSubject<any>(null);
  apiResponse$ = this.submissionStatusSubject.asObservable();
  header = {
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  getListing(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    employeeRewardTypeId: string,
    startDate?: Date,
    endDate?: Date,
  ) {
    return this.http.post<any>(
      `${this.basePath}GetListing/?id=${employeeRewardTypeId}`,
      {
        limit,
        offset,
        text,
        columnIndex,
        sortDirection,
        startDate,
        endDate,
      },
    );
  }

  getQuotationDetailsById(id: string) {
    return this.http.get<any>(`${this.basePath}GetQuotationDetailsById/${id}`);
  }

  requestNewQuotation(reqObj: IRewardsRequestForm, id: string) {
    return this.http.post<any>(
      `${this.basePath}RequestNewQuotation/?id=${id}`,
      reqObj,
    );
  }

  getRewardTypes() {
    return this.http.get<any>(`${this.basePath}GetRewardTypes`);
  }

  getPackagesById(id: string) {
    return this.http.get<any>(`${this.basePath}GetPackagesById/${id}`);
  }

  updateSubmissionStatus(status: boolean): void {
    this.submissionStatusSubject.next(status);
  }

  getSubmissionStatus(): Observable<boolean> {
    return this.submissionStatusSubject.asObservable();
  }
}
