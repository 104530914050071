import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NativeDateAdapter } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxMaskDirective } from "ngx-mask";
import { NgxMatIntlTelInputComponent } from "ngx-mat-intl-tel-input";
import { MALAYSIA_STATES } from "src/app/shared/constants/constant";
import { RewardsService } from "../../../_services/rewards.service";
import { SubmittedDialog } from "../submitted-dialog/submitted-dialog.component";
import { OnlyNumbersDirective } from "src/app/shared/directives/only-numbers.directive";
import { PhoneNumberOnlyDirective } from "src/app/shared/directives/phone-number.directive";
import { EmailOnlyDirective } from "src/app/shared/directives/email.directive";

@Component({
  selector: "event-request-form",
  templateUrl: "./event-request-form.component.html",
  standalone: true,
  providers: [NativeDateAdapter],
  imports: [
    MatListModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatButtonModule,
    FormsModule,
    TranslateModule,
    NgxMatIntlTelInputComponent,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    NgxMaskDirective,
    MatDialogModule,
    OnlyNumbersDirective,
    PhoneNumberOnlyDirective,
    EmailOnlyDirective,
  ],
  styleUrls: ["./event-request-form.component.scss"],
})
export class EventRequestForm {
  requestFormGroup: FormGroup;
  formData: any;
  rewardType: string;
  rewardTypes: any = [];
  rewardPackages: any = [];
  states = MALAYSIA_STATES;
  id;
  minDate;
  today: Date = new Date();
  tomorrow: Date = new Date(this.today.getTime() + 24 * 60 * 60 * 1000);

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<EventRequestForm>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    data: any,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private rewardService: RewardsService,
    private dialog: MatDialog,
  ) {
    this.rewardTypes = data.rewardTypes;
    this.rewardPackages = data.rewardPackages;
    this.id = data.rewardId;
    const queryParams = this.route.snapshot.queryParams;
    this.rewardType = queryParams["type"];
    this.minDate = new Date(this.tomorrow);
    this.minDate.setDate(this.minDate.getDate() + 30); // Tomorrow's date + 30 days
  }

  ngOnInit() {
    this.initEventGroupForm();
  }

  initEventGroupForm(): void {
    this.requestFormGroup = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      mobileNo: [null, [Validators.required, Validators.minLength(8)]], // Adjust validators as needed
      quotationPackageIds: this.fb.array([], [Validators.required]),
      headcount: [null, [Validators.required, Validators.min(1)]],
      eventDate: [null, Validators.required],
      eventAddress1: [null, Validators.required],
      eventAddress2: [null],
      city: ["", Validators.required],
      postcode: ["", Validators.required],
      state: ["", Validators.required],
      remark: [null],
      tnc: [false, Validators.requiredTrue],
    });
  }

  public getCheckboxControl(index: number): FormControl {
    const selectedRewardsFormArray = this.requestFormGroup.get(
      "quotationPackageIds",
    ) as FormArray;
    if (!selectedRewardsFormArray.at(index)) {
      selectedRewardsFormArray.setControl(index, this.fb.control(false));
    }
    return selectedRewardsFormArray.at(index) as FormControl;
  }

  updateSelectedRewards(index: number, isChecked: boolean): void {
    const selectedRewardsFormArray = this.requestFormGroup.get(
      "quotationPackageIds",
    ) as FormArray;
    const control = selectedRewardsFormArray.at(index) as FormControl;
    control.setValue(isChecked);
  }

  public getHoursLabel(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / 60);

    if (hours === 1) {
      return "1 Hour";
    } else if (hours > 1 && hours < 3) {
      return `${hours} Hours`;
    } else {
      return "3 Hours and above";
    }
  }
  public onSubmit(): void {
    if (!this.requestFormGroup.valid) return;
    const data = this.requestFormGroup.getRawValue();
    const selectedRewardsStatus = data.quotationPackageIds;
    const processedRewards = selectedRewardsStatus
      .map((status, index) => {
        if (status) {
          return this.rewardPackages[index].id;
        } else {
          return null;
        }
      })
      .filter((id) => id !== null);

    const formattedData = { ...data, quotationPackageIds: processedRewards };

    this.rewardService.requestNewQuotation(formattedData, this.id).subscribe(
      (res) => {
        this._bottomSheetRef.dismiss();
        this.requestFormGroup.reset();
        this.rewardService.updateSubmissionStatus(true);

        this.openDialog();
      },
      (error) => {},
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SubmittedDialog, {
      backdropClass: "sidesheet-container",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  close(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
