import { Component, Input, ViewChildren } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-page-header",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent {
  @Input() title: string;
}
