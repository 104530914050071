import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FileFunction } from "src/app/shared/utils/file.function";
import { PoliciesService } from "../../../_services/policies.service";

export interface IPolicy {
  effectiveDate: string;
  endDate: string;
  faq: { documentId: string; fileName: string } | null;
  hotline: string;
  image: string;
  isExpired: boolean;
  outstandingAmount: number;
  paymentType: string | null;
  pds: { documentId: string; fileName: string } | null;
  policyId: string;
  policyName: string;
  policyNo: string;
  pw: { documentId: string; fileName: string } | null;
  startDate: string;
  tpaName: string;
}

@Component({
  standalone: true,
  selector: "app-policy-card",
  templateUrl: "./policy-card.component.html",
  styleUrls: ["./policy-card.component.scss"],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
  ],
})
export class PolicyCardComponent {
  @Input() data: IPolicy;

  constructor(private policiesService: PoliciesService) {}

  onDownloadClick(value: { documentId: string; fileName: string }) {
    if (!value) return;
    if (value) {
      this.policiesService.downloadDocumentById(value.documentId).subscribe({
        next: (x: any) => {
          let fileName = value.fileName.split(".")[0];
          FileFunction.downloadFile(x, fileName);
        },
        error: (err) => {},
      });
    }
  }
}
