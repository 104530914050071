<app-page-header title="User Profile"></app-page-header>

<main class="listing-page__content user-profile">
  <ng-container *ngIf="!isError; else errorMessage">
    <!-- ## PROFILE SECTION -->
    <section class="table-toolbar">
      <h3>Profile Information</h3>
      <div class="btn-container">
        <button
          mat-flat-button
          color="primary"
          (click)="handleAction('changePassword')"
          class="btn-primary"
        >
          Change Password
        </button>
        <button
          mat-stroked-button
          (click)="handleAction('update')"
          class="btn-primary-stroked"
        >
          Update
        </button>
      </div>
    </section>

    <!-- ## PROFILE SECTION -->
    <section class="table-container">
      <ng-container *ngIf="userData$ | async as userData; else loadingOverlay">
        <div class="table-listing-wrapper user-profile-info">
          <div class="info-row" *ngFor="let item of userData">
            <div class="info-row-cell">
              <div class="info-row-label">{{ item.label }}</div>
              <div class="info-row-value">{{ item.value }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </section>
  </ng-container>
  <ng-container *ngIf="!isError; else errorMessage">
    <!-- ## TABLE TOOLBAR SECTION -->
    <section class="table-toolbar">
      <h3>Organization</h3>
    </section>

    <!-- ## TABLE SECTION -->
    <section class="table-container">
      <ng-container
        *ngIf="listingData$ | async as listingData; else loadingOverlay"
      >
        <ng-container *ngIf="listingData.data.length; else noDataMessage">
          <div
            class="table-listing-wrapper with-pagination horizontal-scroll"
            *ngIf="!isError"
          >
            <ng-container [ngTemplateOutlet]="loadingOverlay"></ng-container>
            <table mat-table [dataSource]="listingData.data">
              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element" class="lg-col">
                  {{ element.email }}
                </td>
              </ng-container>

              <!-- Full Name Column -->
              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Full Name</th>
                <td mat-cell *matCellDef="let element" class="md-col">
                  {{ element.fullName || "-" }}
                </td>
              </ng-container>

              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Position</th>
                <td mat-cell *matCellDef="let element" class="md-col">
                  {{ element.position || "-" }}
                </td>
              </ng-container>

              <!-- Contact Column -->
              <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef>Phone no.</th>
                <td mat-cell *matCellDef="let element" class="md-col">
                  {{ element.phoneNumber || "-" }}
                </td>
              </ng-container>

              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element" class="md-col">
                  <app-status-tag
                    [status]="element.accountStatus"
                    [color]="getStatusColorMapping(element.accountStatus)"
                  ></app-status-tag>
                </td>
              </ng-container>

              <!-- Date Updated Column -->
              <ng-container matColumnDef="dateUpdated">
                <th mat-header-cell *matHeaderCellDef>Date updated</th>
                <td mat-cell *matCellDef="let element" class="md-col">
                  {{
                    element.lastLoginDate
                      ? (element.lastLoginDate | date : "dd MMM yyyy")
                      : "-"
                  }}
                </td>
              </ng-container>

              <!-- Last Login Date Column -->
              <ng-container matColumnDef="lastLoginDate">
                <th mat-header-cell *matHeaderCellDef>Last Login Date</th>
                <td mat-cell *matCellDef="let element" class="md-col">
                  {{
                    element.lastLoginDate
                      ? (element.lastLoginDate | date : "dd MMM yyyy")
                      : "-"
                  }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator
            [disabled]="isLoading"
            [pageSizeOptions]="[50, 100, 150]"
            [length]="pagination.length"
            [pageSize]="pagination.limit"
            [pageIndex]="pagination.offset - 1"
            (page)="handlePageEvent($event)"
            class="pagination"
          >
          </mat-paginator>
        </ng-container>
      </ng-container>
    </section>
  </ng-container>
</main>

<!-- ## TEMPLATES -->

<!-- No data message -->
<ng-template #noDataMessage>
  <div class="info-message" *ngIf="!isLoading">
    <mat-icon>warning</mat-icon>
    <span>No data found</span>
  </div>
  <ng-container
    *ngIf="isLoading"
    [ngTemplateOutlet]="loadingMessage"
  ></ng-container>
</ng-template>

<!-- Error message -->
<ng-template #errorMessage>
  <div class="error-message">
    <mat-icon>error</mat-icon>
    <span>Uh oh! We are unable to retrieve your data. Please refresh.</span>
  </div>
</ng-template>

<!-- Loading message -->
<ng-template #loadingMessage>
  <div class="info-message">
    <mat-spinner diameter="16"></mat-spinner>
    <span>Loading...</span>
  </div>
</ng-template>

<!-- Loading overlay -->
<ng-template #loadingOverlay>
  <div *ngIf="isLoading" class="loading-overlay">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<mat-menu #menu="matMenu" class="mat-elevation-z2">
  <button mat-menu-item>View status</button>
  <button mat-menu-item class="text-warn">Delete</button>
</mat-menu>
