import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";

@Component({
  selector: "app-view-status-dialog",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
  ],
  templateUrl: "./view-status-dialog.component.html",
  styleUrls: ["./view-status-dialog.component.scss"],
})
export class ViewStatusDialogComponent {
  displayedColumns: string[] = ["status", "date"];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; fileName: string; historyStatus: any[] },
  ) {}
}
