import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { IRewardsRequestForm } from "../../../shared/interfaces/rewards.interface";
import { ApiService } from "src/app/shared/constants/api/api.service";
import { api } from "src/app/shared/constants/api/api-url";
@Injectable({
  providedIn: "root",
})
export class PoliciesService {
  private basePath: string = `${environment.url}/api/EmployeePolicy/`;
  private downloadBasePath: string = `${environment.url}`;

  header = {
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getPolicyListing() {
    return this.http.get<any>(`${this.basePath}GetListing`);
  }


  downloadDocumentById(data?: any) {
    return this.apiService.getDocument(
      this.downloadBasePath + api.EMPLOYEE_DOCUMENT_DOWNLOAD_BY_ID + data,
      this.header,
    );
  }
}
