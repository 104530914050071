import { COMMON_STATUS } from "../constants/constants.global";

export function getStatusColorMapping(status: string) {
  switch (status) {
    case COMMON_STATUS.processing:
      return "orange";
    case COMMON_STATUS.pendingPayment:
      return "grey";
    case COMMON_STATUS.new:
      return "blue";
    case COMMON_STATUS.completed:
      return "green";
    case COMMON_STATUS.paid:
      return "green";
    case COMMON_STATUS.accepted:
      return "sgreen";
    case COMMON_STATUS.cancelled:
      return "orange";
    case COMMON_STATUS.rejected:
      return "red";
    case COMMON_STATUS.invalid:
      return "red";

    default:
      return "";
  }
}
