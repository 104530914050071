import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import * as moment from "moment";
import { BillingsService } from "../../_services/billings.service";
import { FileFunction } from "src/app/shared/utils/file.function";
import { CommonModule } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Subject, take, takeUntil } from "rxjs";
import { AlertService } from "src/app/shared/services/alert.service";

@Component({
  selector: "app-upload-payment-receipt",
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: "./upload-payment-receipt.component.html",
  styleUrls: ["./upload-payment-receipt.component.scss"],
})
export class UploadPaymentReceiptComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  data: any;
  today: Date = new Date();
  isLoading: boolean = false;

  paymentReceiptFile: File;

  protected _onDestroy = new Subject<void>();

  constructor(
    private bottomSheetRef: MatBottomSheetRef<UploadPaymentReceiptComponent>,
    private billingsService: BillingsService,
    private alertService: AlertService,
    @Inject(MAT_BOTTOM_SHEET_DATA) data: any,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      paymentReceiptDate: new FormControl(null, [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  fileChangeEvent(e) {
    if (e.target.files && e.target.files[0]) {
      let file: File = e.target.files[0];
      if (!FileFunction.isImageOrPdf(file)) {
        this.alertService.openSnackBar(
          `Failed to upload ${file.name} - Image & PDF only`,
        );
        return;
      }
      // save file here to state

      this[e.target.name] = file;
    }
    e.target.value = "";
  }

  deleteFile(type: string) {
    if (this[type] != null) {
      this[type] = null;
    }
  }

  close(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  submit() {
    let fd = new FormData();
    if (this.paymentReceiptFile && this.formGroup.value.paymentReceiptDate) {
      fd.append(`File`, this.paymentReceiptFile);
      fd.append(
        `PaymentReceiptDate`,
        moment(this.formGroup.value.paymentReceiptDate).format("DD MMM yyyy"),
      );
    }

    this.isLoading = true;

    this.billingsService
      .uploadPaymentReceipt(this.data.id, fd)
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe({
        next: (x) => {
          this.alertService.openSnackBar(
            "Successfully uploaded payment receipt",
          );
          this.bottomSheetRef.dismiss(true);
        },
        error: (err) => {
          this.alertService.openSnackBar("Error uploading payment receipt");
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
}
