import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { IRewardsRequestForm } from "../../../shared/interfaces/rewards.interface";
@Injectable({
  providedIn: "root",
})
export class ContactService {
  private basePath: string = `${environment.url}/api/EmployeeCompanyStaff/`;

  header = {
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  getAccountManager() {
    return this.http.get<any>(`${this.basePath}GetAccountManager`);
  }
}
