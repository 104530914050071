import { INavItem } from "../interfaces/common.interface";

export const NAV_MENU_LIST: INavItem[] = [
  {
    levelOneId: "",
    url: "employees",
    title: "Employees",
    icon: "people",
    levelTwo: [],
  },
  {
    levelOneId: "",
    url: "billings",
    title: "Billings",
    icon: "receipt",
    levelTwo: [],
  },
  {
    levelOneId: "",
    url: "policies",
    title: "My Policies",
    icon: "verified",
    levelTwo: [],
  },
  {
    levelOneId: "",
    url: "rewards",
    title: "Rewards+",
    icon: "redeem",
    levelTwo: [],
  },
  {
    levelOneId: "",
    url: "contact",
    title: "Contact Us",
    icon: "help",
    levelTwo: [],
  },
  {
    levelOneId: "",
    url: "profile",
    title: "User Profile",
    icon: "person",
    levelTwo: [],
  },
];
