<!-- <div class="request-submitted-dialog">
  <button mat-icon-button class="close-btn" (click)="close()">
    <mat-icon>closed</mat-icon>
  </button>

  <div class="content">
    <img src="assets/images/checkmark.svg" alt="" class="checkmark" />
    <div class="content__wrapper">
      <div class="title">
        {{ "Request Submitted!" }}
      </div>
      <mat-divider></mat-divider>
      <div class="body">
        {{
          "Thank you for your submission. We have received it and are currently reviewing. We will reach out shortly to confirm your participation or request any additional information."
        }}
      </div>
    </div>
  </div>
</div> -->
<div class="header">
  <button mat-icon-button class="close-btn" (click)="close()">
    <mat-icon class="close-icon">closed</mat-icon>
  </button>
</div>
<mat-dialog-content class="content">
  <div>
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      class="checkmark"
    >check_circle</mat-icon>
  </div>
  <div class="title">
    {{ "Request Submitted!" }}
  </div>
  <mat-divider class=""></mat-divider>
  <div class="body">
    {{
      "Thank you for your submission. We have received it and are currently reviewing. We will reach out shortly to confirm your participation or request any additional information."
    }}
  </div>
</mat-dialog-content>
