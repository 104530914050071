import { Routes } from "@angular/router";
import { DashboardComponent } from "./shared/layouts/dashboard/dashboard.component";
import { PoliciesComponent } from "./modules/policies/policies.component";
import { EmployeesComponent } from "./modules/employees/employees.component";
import { BillingsComponent } from "./modules/billings/billings.component";
import { RewardsComponent } from "./modules/rewards/rewards.component";
import { ContactComponent } from "./modules/contact/contact.component";
import { ProfileComponent } from "./modules/profile/profile.component";
import { AuthGuard } from "./shared/services/guard.service";

export const APP_ROUTES: Routes = [
  {
    path: "authentication",
    loadChildren: () =>
      import("./modules/authentication/auth.routes").then((m) => m.AUTH_ROUTES),
  },
  {
    path: "",
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: "policies",
        component: PoliciesComponent,
      },
      {
        path: "employees",
        component: EmployeesComponent,
      },
      {
        path: "billings",
        component: BillingsComponent,
      },
      {
        path: "rewards",
        component: RewardsComponent,
      },

      {
        path: "contact",
        component: ContactComponent,
      },
      {
        path: "profile",
        component: ProfileComponent,
      },
    ],
  },
];
