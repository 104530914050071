<mat-drawer-container class="dashboard">
  <mat-drawer
    #drawer
    [opened]="screenWidth > screenSizes.DESKTOP"
    [mode]="screenWidth > screenSizes.DESKTOP ? 'side' : 'over'"
  >
    <app-site-nav></app-site-nav>
  </mat-drawer>
  <mat-drawer-content>
    <app-header></app-header>
    <div class="dashboard-content">
      <div class="dashboard-content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
