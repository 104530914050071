import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { PageHeaderComponent } from "src/app/shared/components/page-header/page-header.component";
import { ContactService } from "./_services/contact.service";
import { IAccountManager } from "./_interfaces/contact.interface";

@Component({
  selector: "app-contact",
  standalone: true,
  imports: [CommonModule, PageHeaderComponent, MatIconModule],
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent {
  accountManagerList: IAccountManager[] = [];
  constructor(private contactService: ContactService) {
    this.getData();
  }

  getData() {
    this.contactService.getAccountManager().subscribe((res) => {
      this.accountManagerList = res;
    });
  }
}
