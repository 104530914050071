import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";

@Component({
  selector: "app-view-status-dialog",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
  ],
  templateUrl: "./view-payment-history-dialog.component.html",
  styleUrls: ["./view-payment-history-dialog.component.scss"],
})
export class ViewPaymentHistoryDialogComponent {
  displayedColumns: string[] = [
    "dateUpdated",
    "status",
    "paymentDate",
    "by",
    "remarks",
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}
}
