<mat-form-field appearance="outline">
  <mat-label>Date</mat-label>
  <mat-select [(ngModel)]="filterSelected">
    <mat-select-trigger>
      <ng-container [ngSwitch]="filterSelected">
        <span *ngSwitchCase="'today'">Today</span>
        <span *ngSwitchCase="'week'">This Week</span>
        <span *ngSwitchCase="'month'">This Month</span>
        <span *ngSwitchCase="'year'">This Year</span>
        <span *ngSwitchDefault>
          <span *ngIf="endDate">
            {{ startDate | date : "dd/MM/yy" }}
            - {{ endDate | date : "dd/MM/yy" }}
          </span>
        </span>
      </ng-container>
    </mat-select-trigger>
    <mat-option value="today" (click)="setTodayDate()">Today</mat-option>
    <mat-option value="week" (click)="setCurrentWeekDate()"
      >This Week</mat-option
    >
    <mat-option value="month" (click)="setCurrentMonthDate()"
      >This Month</mat-option
    >
    <mat-option value="year" (click)="setCurrentYearDate()"
      >This Year</mat-option
    >
    <mat-option value="custom" (click)="picker.open()">Custom Range</mat-option>
    <mat-date-range-input style="display: none" [rangePicker]="picker">
      <input
        matStartDate
        [(ngModel)]="startDate"
        (dateChange)="onStartDateSelected($event)"
        placeholder="Start date"
      />
      <input
        matEndDate
        [(ngModel)]="endDate"
        (dateChange)="onEndDateSelected($event)"
        placeholder="End date"
      />
    </mat-date-range-input>
    <mat-date-range-picker
      #picker
      (opened)="startDateOpen()"
      (closed)="onDateRangePickerClosed()"
    >
    </mat-date-range-picker>
  </mat-select>
  <button
    mat-icon-button
    matSuffix
    (click)="resetDateFilter()"
    *ngIf="filterSelected !== null"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
