<app-page-header title="Contact Us">
  <!-- ## TOOLBAR SECTION -->
  <!-- <section class="toolbar-section">
        <button mat-flat-button color="primary">
            <mat-icon>upload</mat-icon>Upload
        </button>
        <button mat-stroked-button>
            <mat-icon>download</mat-icon>Download Template
        </button>
        <button mat-button><mat-icon>refresh</mat-icon>Refresh</button>
    </section> -->
</app-page-header>
<div class="contact-us">
  <div class="contact-us__container">
    <div class="contact-us__title">Need our help?</div>
    <div>
      You can always contact us from Monday to Friday, 9am - 6pm, except public
      holidays.
    </div>
    <hr class="divider" />
    <div class="contact-us__content">
      <div class="contact-us__content-left">
        <div class="content-section">
          <mat-icon class="icon"> location_on</mat-icon>
          <div>
            D-3A-06 & D-3A-07, Menara Suezcap 1, <br />
            KL Gateway, No. 2 Jalan Kerinchi, <br />
            Gerbang Kerinchi Lestari, 59200 Kuala Lumpur
          </div>
        </div>
        <div class="content-section">
          <mat-icon class="icon">email</mat-icon>
          <div>
            {{ " eb@policystreet.com" }}
          </div>
        </div>
        <div class="content-section">
          <mat-icon class="icon">phone</mat-icon>
          <div>+6012-34567789 (Whatsapp)</div>
        </div>
      </div>
      <div class="contact-us__content-right">
        <div class="content-section" *ngFor="let item of accountManagerList">
          <mat-icon class="icon">person</mat-icon>
          <div>
            <div class="content-section__name">
              {{ item.name }} - Account Manager
            </div>
            <div>{{ item.email }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
