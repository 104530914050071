import { Directive, ElementRef, HostListener, Optional } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[emailOnly]",
  standalone: true,
})
export class EmailOnlyDirective {
  private emailPattern: RegExp = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,3}$/;

  constructor(
    private el: ElementRef,
    @Optional() private ngControl: NgControl,
  ) {}

  @HostListener("input", ["$event"]) onInputChange(event: any) {
    const inputValue = this.el.nativeElement.value;

    // Validate input value against the email pattern
    if (this.ngControl) {
      if (this.emailPattern.test(inputValue)) {
        this.ngControl.control.setErrors(null); // No errors
      } else {
        this.ngControl.control.setErrors({ invalidEmail: true }); // Set error
      }
    }
  }
}
