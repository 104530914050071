import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import {
  MatDialogModule,
  MatDialogRef
} from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "submitted-dialog",
  templateUrl: "./submitted-dialog.component.html",
  standalone: true,
  providers: [NativeDateAdapter],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatDividerModule,
    MatDialogModule,
  ],
  styleUrls: ["./submitted-dialog.component.scss"],
})
export class SubmittedDialog {
  constructor(
    public dialogRef: MatDialogRef<SubmittedDialog>,
  ) {}

  ngOnInit() {}
  close(): void {
    this.dialogRef.close(true);
  }
}
