import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { Subject } from "rxjs";
import { AlertService } from "src/app/shared/services/alert.service";
import { FileFunction } from "src/app/shared/utils/file.function";
import { EmployeesService } from "../../_services/employees.service";

@Component({
  selector: "app-upload-employee-movement-dialog",
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
  ],
  templateUrl: "./upload-employee-movement-dialog.component.html",
  styleUrls: ["./upload-employee-movement-dialog.component.scss"],
})
export class UploadEmployeeMovementDialogComponent
  implements OnInit, OnDestroy
{
  formGroup: FormGroup;
  isLoading: boolean = false;

  monthList = [
    { val: 1, text: "Jan" },
    { val: 2, text: "Feb" },
    { val: 3, text: "Mar" },
    { val: 4, text: "Apr" },
    { val: 5, text: "May" },
    { val: 6, text: "June" },
    { val: 7, text: "July" },
    { val: 8, text: "Aug" },
    { val: 9, text: "Sep" },
    { val: 10, text: "Oct" },
    { val: 11, text: "Nov" },
    { val: 12, text: "Dec" },
  ];

  notShow: boolean = true;
  yearRange: number[];
  fileToUpload: File;

  selectedMonth: string;
  selectedYear: string;

  protected _onDestroy = new Subject<void>();

  constructor(
    private bottomSheetRef: MatBottomSheetRef<UploadEmployeeMovementDialogComponent>,
    private employeesService: EmployeesService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.getYear();

    this.formGroup = new FormGroup({
      month: new FormControl(null, [Validators.required]),
      year: new FormControl(null, [Validators.required]),
      employeeMovementFile: new FormControl(null, [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getYear() {
    let year = new Date().getFullYear();
    let range = [];
    range.push(year);
    for (var i = 1; i < 7; i++) {
      range.push(year + i);
    }

    this.yearRange = range;
  }

  fileChangeEvent(e) {
    if (e.target.files && e.target.files[0]) {
      let file: File = e.target.files[0];
      if (!FileFunction.isImageOrPdf(file)) {
        this.alertService.openSnackBar(
          `Failed to upload ${file.name} - Image & PDF only`,
        );
        return;
      }
      this.fileToUpload = file;
    }
    e.target.value = "";
  }

  deleteFile(type: string) {
    if (this[type] != null) {
      this[type] = null;
    }
  }

  close(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  submit() {
    if (this.formGroup.invalid) return;
    if (this.fileToUpload) {
      let formData = new FormData();
      formData.append(
        "Title",
        "employee" +
          this.formGroup.get("year").value +
          "-" +
          this.formGroup.get("month").value,
      );
      formData.append("File", this.fileToUpload);
      this.employeesService.uploadDocument(formData).subscribe({
        next: (x: any) => {
          if (x) {
            this.bottomSheetRef.dismiss(true);
          }
        },
        error: (err) => {
          this.alertService.openSnackBar(err.error);
        },
      });
    }
  }
}
