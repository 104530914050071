<div class="view-status-dialog">
  <mat-toolbar class="dialog__header">
    <h4>View Status History</h4>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="mb-sm">
      <span>File: </span>
      <span>{{ data.title }}</span>
    </div>

    <table mat-table [dataSource]="data.historyStatus">
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.status }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date updated</th>
        <td mat-cell *matCellDef="let element">
          {{ element.datetime | date : "dd MMM yyyy hh:mm a" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-dialog-content>
</div>
