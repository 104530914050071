<div [ngSwitch]="color">
  <div class="label-tag grey with-icon" *ngSwitchCase="'grey'">
    {{ status || "-" }}
  </div>
  <div class="label-tag blue with-icon" *ngSwitchCase="'blue'">
    {{ status || "-" }}
  </div>
  <div class="label-tag green with-icon" *ngSwitchCase="'green'">
    {{ status || "-" }}
  </div>
  <div class="label-tag sgreen with-icon" *ngSwitchCase="'sgreen'">
    {{ status || "-" }}
  </div>
  <div class="label-tag red with-icon" *ngSwitchCase="'red'">
    {{ status || "-" }}
  </div>
  <div class="label-tag orange with-icon" *ngSwitchCase="'orange'">
    {{ status || "-" }}
  </div>
  <div *ngSwitchCase="'-'">-</div>
</div>
