<div class="close-btn-wrapper">
  <button mat-icon-button (click)="close($event)">
    <mat-icon>closed</mat-icon>
  </button>
</div>

<form
  class="side-sheet__form form"
  [formGroup]="formGroup"
  (ngSubmit)="submit()"
>
  <ng-container>
    <div class="form__title">Upload Employee Movement</div>
  </ng-container>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Month</mat-label>
    <mat-select formControlName="month" placeholder="month">
      <mat-option *ngFor="let month of monthList" [value]="month.text">
        {{ month.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Year</mat-label>
    <mat-select formControlName="year" placeholder="year">
      <mat-option *ngFor="let year of yearRange" [value]="year">
        {{ year }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="form__file-input">
    <div class="file-upload__button" (click)="fileInput.click()">
      <p>Upload file *</p>
      <mat-icon>upload</mat-icon>

      <input
        formControlName="employeeMovementFile"
        type="file"
        name="employeeMovementFile"
        #Image
        accept="application/pdf,image/*"
        style="display: none"
        #fileInput
        (change)="fileChangeEvent($event)"
      />
    </div>
    <div *ngIf="fileToUpload">
      <mat-divider></mat-divider>
      <div class="file-upload__content">
        <span class="mr-sm">
          {{ fileToUpload.name }}
        </span>
        <button
          mat-icon-button
          [disabled]="isLoading"
          (click)="deleteFile('fileToUpload')"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <button
    mat-flat-button
    type="submit"
    color="primary"
    class="w-full mt-md"
    [disabled]="formGroup.invalid || !fileToUpload || isLoading"
  >
    <span>Upload payment receipt</span>
    <mat-icon *ngIf="isLoading"
      ><mat-spinner diameter="18"> </mat-spinner>
    </mat-icon>
  </button>
</form>
