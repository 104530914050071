import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ApiService } from "../../../shared/constants/api/api.service";
import { api } from "src/app/shared/constants/api/api-url";

@Injectable({
  providedIn: "root",
})
export class BillingsService {
  basePath: string = environment.url;

  header = {
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getBillingLists(objRequest?: any, options?: any): Observable<any> {
    return this.apiService.post(
      this.basePath + api.BILLING_GET_LIST,
      objRequest,
      this.header,
    );
  }

  downloadFileById(objRequest?: any, options?: any): Observable<any> {
    return this.apiService.getDocument(
      this.basePath + api.EMPLOYEE_DOCUMENT_DOWNLOAD_BY_ID + objRequest,
      this.header,
    );
  }

  getStatusHistory(id: string): Observable<any> {
    return this.apiService.get(
      this.basePath + api.BILLING_GET_STATUS_HISTORY + id,
      this.header,
    );
  }

  uploadPaymentReceipt(id: string, objRequest?: FormData): Observable<any> {
    return this.apiService.post(
      this.basePath + api.BILLING_UPLOAD_PAYMENT_RECEIPT + id,
      objRequest,
      { Accept: "application/json" },
    );
  }

  getTypeSelection(): Observable<any> {
    return this.apiService.get(this.basePath + api.BILLING_GET_TYPE_SELECTION);
  }

  getPaymentStatusSelection(): Observable<any> {
    return this.apiService.get(
      this.basePath + api.BILLING_GET_PAYMENT_STATUS_SELECTION,
    );
  }

  getPolicySelection(): Observable<any> {
    return this.apiService.get(
      this.basePath + api.BILLING_GET_POLICY_SELECTION,
    );
  }
}
