export const api = {
  /* FIRST TIME LOGIN */
  FIRST_TIME_LOGIN: "/api/EmployeeUser/FirstTimeLogin",
  FIRST_TIME_LOGIN_VERIFY: "/api/EmployeeUser/FirstTimeLoginVerify",

  /*LOGIN */
  LOGIN: "/api/EmployeeUser/Login",
  SEND_FORGOT_PASSWORD_EMAIL: "/api/EmployeeUser/SendForgotPasswordEmail",
  RESET_PASSWORD: "/api/EmployeeUser/ResetPassword",
  RESET_PASSWORD_LINK_VERIFY: "/api/EmployeeUser/ResetPasswordVerify",
  GET_USER_COMPANY_LIST: "/api/EmployeeUser/GetUserLoginCompanySelection",

  /* CLAIM */
  CLAIM_GET_LIST: "/api/EmployeeClaim/GetClaimReports",

  /* EMPLOYEE MOVEMENT */
  EMPLOYEE_MOVEMENT_GET_LIST: "/api/EmployeeMovement/GetListing",
  EMPLOYEE_MOVEMENT_GET_STATUS_HISTORY:
    "/api/EmployeeMovement/GetStatusHistory/",
  EMPLOYEE_MOVEMENT_DOWNLOAD_TEMPLATE: "/api/EmployeeMovement/DownloadTemplate",
  EMPLOYEE_MOVEMENT_DOWNLOAD_CSV: "/api/EmployeeMovement/DownloadCSV",
  EMPLOYEE_MOVEMENT_UPLOAD: "/api/EmployeeMovement/Upload",
  EMPLOYEE_MOVEMENT_GET_STATUS_LIST: "/api/EmployeeMovement/GetStatusList",
  EMPLOYEE_MOVEMENT_DELETE_MOVEMENT_BY_ID:
    "/api/EmployeeMovement/DeleteMovement/",

  /* EMPLOYEE DOCUMENT */
  EMPLOYEE_DOCUMENT_DOWNLOAD_BY_ID: "/api/EmployeeDocument/DownloadById/",
  EMPLOYEE_DOCUMENT_DELETE_BY_ID: "/api/EmployeeDocument/DeleteById/",

  /* POLICY */
  POLICY_GET_LIST: "/api/EmployeePolicy/GetListing",
  POLICY_GET_COMPANY_DETAILS: "/api/EmployeePolicy/GetCompanyDetail",

  /* BILLING */
  BILLING_GET_LIST: "/api/EmployeeBilling/GetListing",
  BILLING_GET_STATUS_HISTORY: "/api/EmployeeBilling/GetStatusHistory/",
  BILLING_UPLOAD_PAYMENT_RECEIPT:
    "/api/EmployeeBilling/UploadBillPaymentReceipt/",
  BILLING_GET_TYPE_SELECTION:
    "/api/EmployeeBilling/GetEmployeeBillingTypeSelection",
  BILLING_GET_PAYMENT_STATUS_SELECTION:
    "/api/EmployeeBilling/GetEmployeeBillPaymentStatusSelection",
  BILLING_GET_POLICY_SELECTION: "/api/EmployeeBilling/GetPolicySelection",

  /* EMPLOYEE COMPANY STAFF */
  ACCOUNT_MANAGER_GET_LIST: "/api/EmployeeCompanyStaff/GetAccountManager",

  // User Profile
  USER_PROFILE_GET_LIST: "/api/EmployeeProfile/GetProfileInfo",
  USER_PROFILE_GET_ORGANIZATION_LIST:
    "/api/EmployeeProfile/GetOrganizationList",
  USER_PROFILE_UPDATE_PROFILE_INFO: "/api/EmployeeProfile/UpdateProfileInfo",
  USER_PROFILE_UPDATE_ACCOUNT_PASSWORD:
    "/api/EmployeeProfile/UpdateAccountPassword",
};
