<app-page-header title="Billings">
  <!-- ## TOOLBAR SECTION -->

  <section class="toolbar-section">
    <button mat-stroked-button (click)="handleRefreshData()">
      <mat-icon>refresh</mat-icon>Refresh
    </button>
  </section>
</app-page-header>

<main class="listing-page__content">
  <ng-container
    *ngIf="(apiStatus$ | async) === 'ERROR'; then errorMessage"
  ></ng-container>
  <!-- ## TABLE TOOLBAR SECTION -->
  <section class="table-toolbar">
    <div class="table-toolbar__filters">
      <ng-container *ngIf="billingTypeList$ | async as billingTypeList">
        <app-multi-dropdown
          type="billingType"
          title="Billing type"
          [selectedFilters]="filterProperties.billingTypeList"
          [options]="billingTypeList"
          (filterFunction)="handleFilterChange($event)"
        ></app-multi-dropdown
      ></ng-container>

      <ng-container *ngIf="policyList$ | async as policyList">
        <app-multi-dropdown
          type="policy"
          title="Policy"
          [selectedFilters]="filterProperties.policyList"
          [options]="policyList"
          (filterFunction)="handleFilterChange($event)"
        ></app-multi-dropdown
      ></ng-container>

      <ng-container *ngIf="statusList$ | async as statusList">
        <app-multi-dropdown
          type="status"
          title="Status"
          [selectedFilters]="filterProperties.statusList"
          [options]="statusList"
          (filterFunction)="handleFilterChange($event)"
        ></app-multi-dropdown
      ></ng-container>

      <!-- Clear all filters button -->
      <button
        mat-button
        [disabled]="isLoadingState"
        *ngIf="
          filterProperties.policyList.length ||
          filterProperties.statusList.length ||
          filterProperties.billingTypeList.length
        "
        (click)="handleClearAllFilters()"
      >
        Clear all filters
      </button>
    </div>
  </section>

  <!-- ## TABLE SECTION -->

  <section class="table-container">
    <div
      class="table-listing-wrapper"
      *ngIf="!((apiStatus$ | async) === 'ERROR')"
    >
      <table mat-table [dataSource]="(listingData$ | async)!">
        <!-- Date Joined Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell mat-column-date *matCellDef="let element">
            {{ element.datetime | date : "dd MMM yyyy HH:mm" }}
          </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell mat-column-type *matCellDef="let element">
            {{ element.type }}
          </td>
        </ng-container>

        <!-- Policy Column -->
        <ng-container matColumnDef="policy">
          <th mat-header-cell *matHeaderCellDef>Policy</th>
          <td mat-cell mat-column-policy *matCellDef="let element">
            {{ element.policy }}
          </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td
            mat-cell
            mat-column-amount
            *matCellDef="let element"
            [ngClass]="element.type == 'Credit Note' ? 'text-primary' : ''"
          >
            RM {{ element.amount | number : "1.2-2" }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell mat-column-status *matCellDef="let element">
            <app-status-tag
              [status]="element.paymentStatus"
              [color]="getStatusColorMapping(element.paymentStatus)"
            ></app-status-tag>
          </td>
        </ng-container>

        <!-- Remarks Column -->
        <ng-container matColumnDef="remarks">
          <th mat-header-cell *matHeaderCellDef>Remarks</th>
          <td mat-cell mat-column-remarks *matCellDef="let element">
            {{ element.remark }}
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell mat-column-action *matCellDef="let element">
            <div class="flex">
              <div
                #tooltip="matTooltip"
                matTooltip="{{
                  !element?.paymentStatus ||
                  element?.paymentStatus !== 'Pending Payment' ||
                  element?.paymentStatus == 'Rejected'
                    ? 'Payment Receipt has already been uploaded'
                    : 'Upload Payment Receipt'
                }}"
              >
                <button
                  mat-icon-button
                  [disabled]="
                    !element?.paymentStatus ||
                    element?.paymentStatus !== 'Pending Payment' ||
                    element?.paymentStatus == 'Rejected'
                  "
                  (click)="uploadPaymentReceipt(element)"
                >
                  <mat-icon>upload</mat-icon>
                </button>
              </div>

              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{ element: element }"
                (click)="selectedRow = element; $event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length" *ngIf="isLoadingState">
            <ngx-skeleton-loader
              count="5"
              [theme]="{
                height: '40px',
                'animation-duration': '1s'
              }"
            >
            </ngx-skeleton-loader>
          </td>
          <td *ngIf="showEmptyState" [attr.colspan]="displayedColumns.length">
            <app-empty-state></app-empty-state>
          </td>
        </tr>
      </table>

      <ng-container *ngIf="paginationProperties">
        <mat-paginator
          [disabled]="isLoadingState"
          [pageSizeOptions]="[5, 10, 20, 50, 100]"
          [length]="paginationProperties.length"
          [pageSize]="paginationProperties.limit"
          [pageIndex]="paginationProperties.offset - 1"
          (page)="handlePageEvent($event)"
        >
        </mat-paginator
      ></ng-container>
    </div>
  </section>
</main>

<!-- ## TEMPLATES -->

<!-- Error message -->
<ng-template #errorMessage>
  <div class="error-message">
    <mat-icon>error</mat-icon>
    <span
      >Uh oh! We are unable to retrieve your data. Please refresh. If the issue
      persists, kindly contact our support team.</span
    >
  </div>
</ng-template>

<mat-menu #menu="matMenu" class="mat-elevation-z2">
  <ng-template matMenuContent let-element="element">
    <button mat-menu-item (click)="downloadBillingFile(selectedRow)">
      <mat-icon>download</mat-icon>Billing file
    </button>
    <button
      mat-menu-item
      (click)="downloadPaymentReceiptFile(selectedRow)"
      [disabled]="element.paymentStatus == 'Pending Payment'"
      *ngIf="element.type == 'Invoice'"
    >
      <mat-icon>download</mat-icon>Payment receipt
    </button>
    <button
      mat-menu-item
      (click)="viewBillingsPaymentHistory(selectedRow)"
      [disabled]="element.paymentStatus == 'Pending Payment'"
    >
      <mat-icon>history</mat-icon> View payment history
    </button></ng-template
  >
</mat-menu>
