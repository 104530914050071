import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "../services/auth.service";
import { IAuthCredentials, IUserCompany } from "../interfaces/user.interface";

@Injectable({
  providedIn: "root",
})
export class CompanyStore {
  basePath: string = environment.url;
  subject = new BehaviorSubject("");
  selectedCompany$ = this.subject.asObservable();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getUserCompanyList(): Observable<IUserCompany[]> {
    return this.http
      .get<{ companies: IUserCompany[] }>(
        `${this.basePath}/api/EmployeeUser/GetUserCompanyList`,
      )
      .pipe(map((x) => x.companies));
  }

  switchCompany(selectedCompanyId: string): Observable<IAuthCredentials> {
    return this.http
      .get<IAuthCredentials>(
        `${this.basePath}/api/EmployeeUser/SwitchCompany/${selectedCompanyId}`,
      )
      .pipe(
        tap((res) => {
          localStorage.setItem("selectedCompany", selectedCompanyId);
          localStorage.setItem("companyLogo", res.companyLogoUrl);
          this.authService.setToken(res.token);
        }),
        finalize(() => this.subject.next(selectedCompanyId)),
      );
  }

  getSelectedCompany() {
    return this.selectedCompany$;
  }
}
