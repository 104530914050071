<div class="close-btn-wrapper">
  <button mat-icon-button (click)="close($event)">
    <mat-icon>closed</mat-icon>
  </button>
</div>

<form class="form" [formGroup]="requestFormGroup" (ngSubmit)="onSubmit()">
  <ng-container [ngSwitch]="rewardType">
    <ng-container *ngSwitchCase="'event'">
      <div class="form__title">Event Request Form</div>
      <div class="mt-sm">
        Fill in this form to submit a new event request. Our team will respond
        soon.
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'bloodTest'">
      <div class="form__title">
        {{ "rewards.bloodTestQuotation" | translate }}
      </div>
      <div class="mt-sm">
        {{ "rewards.fillInQuotation" | translate }}
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'vaccination'">
      <div class="form__title">
        {{ "rewards.vaccinationQuotation" | translate }}
      </div>
      <div class="mt-sm">
        {{ "rewards.fillInQuotation" | translate }}
      </div>
    </ng-container>
  </ng-container>
  <mat-form-field appearance="outline">
    <mat-label>{{ "general.name" | translate }}</mat-label>
    <input matInput autocomplete="name" formControlName="name" />
  </mat-form-field>
  <mat-form-field appearance="outline" class="">
    <mat-label>{{ "general.email" | translate }}</mat-label>
    <input matInput formControlName="email" autocomplete="email" emailOnly />
    <mat-error
      *ngIf="
        requestFormGroup.get('email').value &&
        requestFormGroup.get('email').hasError('pattern')
      "
    >
      {{ "error.invalidEmail" | translate }}</mat-error
    >
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ "general.mobileNo" | translate }}</mat-label>
    <input matInput formControlName="mobileNo" prefix="+" phoneNumberOnly />
    <mat-error *ngIf="requestFormGroup.controls['mobileNo']?.errors?.required"
      >Required Field</mat-error
    >
    <mat-error
      *ngIf="requestFormGroup.controls['mobileNo']?.errors?.validatePhoneNumber"
      >Invalid Number</mat-error
    >
  </mat-form-field>
  <div class="form__checkboxes mt-lg">
    <ng-container [ngSwitch]="rewardType">
      <ng-container *ngSwitchCase="'event'">
        <div class="form__checkboxes__header">
          {{ "rewards.wellnessActivities" | translate
          }}<span class="asterisk"> *</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'bloodTest'">
        <div class="form__checkboxes__header">
          {{ "rewards.screeningPackages" | translate
          }}<span class="asterisk"> *</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'vaccination'">
        <div class="form__checkboxes__header">
          {{ "rewards.vaccinationQuotation" | translate
          }}<span class="asterisk"> *</span>
        </div>
      </ng-container>
    </ng-container>
    <div class="form__checkboxes__subheader">
      {{ "rewards.pleaseSelect" | translate }}
    </div>
    <div formArrayName="quotationPackageIds">
      <ng-container *ngFor="let reward of rewardPackages; let i = index">
        <mat-checkbox
          *ngIf="rewardType === 'event'"
          [formControl]="getCheckboxControl(i)"
          (change)="updateSelectedRewards(i, $event.checked)"
        >
          {{ reward.name }} - ({{ getHoursLabel(reward.totalMinutes) }})
        </mat-checkbox>
        <mat-checkbox
          *ngIf="rewardType !== 'event'"
          [formControl]="getCheckboxControl(i)"
          (change)="updateSelectedRewards(i, $event.checked)"
        >
          {{ reward.name }}
        </mat-checkbox>
      </ng-container>
    </div>
  </div>
  <div class="form__row mt-md">
    <mat-form-field appearance="outline">
      <mat-label>{{ "rewards.headcount" | translate }}</mat-label>
      <input
        matInput
        autocomplete="headcount"
        formControlName="headcount"
        min="1"
        onlyNumbers
      />
      <mat-error
        *ngIf="
          requestFormGroup.get('headcount').value &&
          requestFormGroup.get('headcount').hasError('minValue')
        "
      >
        {{ "error.valueAtLeast1" | translate }}</mat-error
      >
    </mat-form-field>
    <mat-form-field class="form__date-field" appearance="outline">
      <mat-label>Choose a date</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        formControlName="eventDate"
        [min]="minDate"
      />
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>{{ "rewards.eventAddress1" | translate }}</mat-label>
    <input
      matInput
      autocomplete="eventAddress1"
      formControlName="eventAddress1"
    />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ "rewards.eventAddress2" | translate }}</mat-label>
    <input
      matInput
      autocomplete="eventAddress2"
      formControlName="eventAddress2"
    />
  </mat-form-field>
  <div class="form__row">
    <mat-form-field appearance="outline">
      <mat-label>{{ "general.city" | translate }}</mat-label>
      <input matInput autocomplete="city" formControlName="city" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ "general.postCode" | translate }}</mat-label>
      <input
        matInput
        required
        autocomplete="postal-code"
        mask="00000"
        formControlName="postcode"
      />
    </mat-form-field>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>{{ "general.state" | translate }}</mat-label>
    <mat-select formControlName="state" placeholder="Select state">
      <mat-option *ngFor="let state of states" [value]="state">{{
        state
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    class="form__textarea"
    appearance="outline"
    floatLabel="always"
  >
    <mat-label>
      <div class="form__textarea__label">
        {{ "rewards.remarks" | translate }}
        <span>{{ "rewards.remarksText" | translate }}</span>
      </div>
    </mat-label>
    <textarea
      matInput
      class="form__textarea__input mt-sm"
      rows="6"
      formControlName="remark"
    ></textarea>
  </mat-form-field>
  <mat-checkbox class="tnc-checkbox" formControlName="tnc">{{
    "rewards.bySubmittingThisForm" | translate
  }}</mat-checkbox>
  <button
    class="w-full mt-sm"
    type="submit"
    color="primary"
    [disabled]="!requestFormGroup.valid"
    mat-flat-button
  >
    {{ "SUBMIT" | translate }}
  </button>
</form>
