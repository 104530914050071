<div class="view-payment-history-dialog">
  <mat-toolbar class="dialog__header">
    <h4>Payment History</h4>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <table mat-table [dataSource]="data.list">
      <ng-container matColumnDef="dateUpdated">
        <th mat-header-cell *matHeaderCellDef>Date updated</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateUpdated | date : "dd MMM yyyy hh:mm a" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.paymentStatus }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paymentDate">
        <th mat-header-cell *matHeaderCellDef>Payment date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.paymentDate | date : "dd MMM yyyy hh:mm a" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="by">
        <th mat-header-cell *matHeaderCellDef>By</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdBy }}
        </td>
      </ng-container>
      <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef>Remarks</th>
        <td mat-cell *matCellDef="let element">
          {{ element.remark }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-dialog-content>
</div>
