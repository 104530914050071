import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { IEmployee } from "../../_interfaces/employees.interface";

@Component({
  selector: "app-delete-confirmation-dialog",
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatButtonModule, MatDialogModule],
  templateUrl: "./delete-confirmation-dialog.component.html",
  styleUrls: ["./delete-confirmation-dialog.component.scss"],
})
export class DeleteConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IEmployee) {}
}
